export const TABLE_COLUMNS_SUBMIT_FEE = [
  {
    id: "rowTitle",
    name: "",
    type: "text",
  },
  {
    id: "individual",
    name: "개인전",
    type: "text",
  },
  {
    id: "teamSparring",
    name: "겨루기 단체전",
    type: "text",
  },
  {
    id: "teamForm",
    name: "품새 단체전",
    type: "text",
  },
  {
    id: "pairForm",
    name: "품새 페어",
    type: "text",
  },
  {
    id: "second",
    name: "세컨",
    type: "text",
  },
  {
    id: "sum",
    name: "합계",
    type: "text",
  },
];

export const TABLE_COLUMNS_REPRESENTATIVE_REGIST = [
  {
    id: "userName",
    name: "성명",
    type: "text",
  },
  {
    id: "phoneNumber",
    name: "연락처",
    type: "input",
    detail: {
      validators: [],
    },
  },
  {
    id: "universityName",
    name: "학교",
    type: "text",
  },
  {
    id: "depositorName",
    name: "입금자명",
    type: "input",
    detail: {
      validators: [],
    },
  },
];

export const TABLE_COLUMNS_REPRESENTATIVE_CHECK = [
  {
    id: "userName",
    name: "성명",
    type: "text",
  },
  {
    id: "phoneNumber",
    name: "연락처",
    type: "text",
  },
  {
    id: "universityName",
    name: "학교",
    type: "text",
  },
  {
    id: "depositorName",
    name: "입금자명",
    type: "text",
  },
];

export const TABLE_COLUMNS_DEPOSIT_INFO = [
  {
    id: "depositOwnerName",
    name: "입금주",
    type: "text",
  },
  {
    id: "accountBank",
    name: "입금은행",
    type: "text",
  },
  {
    id: "accountNumber",
    name: "입금계좌",
    type: "text",
  },
];

export const TABLE_COLUMNS_REFUND_INFO_REGIST = [
  {
    id: "depositOwnerName",
    name: "입금주명",
    type: "input",
    detail: {
      validators: [],
    },
  },
  {
    id: "accountBank",
    name: "환불은행",
    type: "input",
    detail: {
      validators: [],
    },
  },
  {
    id: "accountNumber",
    name: "환불계좌",
    type: "input",
    detail: {
      validators: [],
    },
  },
];

export const TABLE_COLUMNS_REFUND_INFO_CHECK = [
  {
    id: "depositOwnerName",
    name: "입금주명",
    type: "text",
  },
  {
    id: "accountBank",
    name: "환불은행",
    type: "text",
  },
  {
    id: "accountNumber",
    name: "환불계좌",
    type: "text",
  },
];
