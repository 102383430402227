import React, { useContext, useEffect, useState, useCallback } from "react";

import { HttpContext } from "../../../shared/context/http-context";
import { AuthContext } from "../../../shared/context/auth-context";
import {
  TABLE_COLUMNS_DEPOSIT_INFO,
  TABLE_COLUMNS_REPRESENTATIVE_REGIST,
  TABLE_COLUMNS_REPRESENTATIVE_CHECK,
  TABLE_COLUMNS_SUBMIT_FEE,
} from "../../../shared/util/submit/submit-columns";

import SubmitTable from "../components/SubmitTable";
import Button from "../../../shared/components/TableInputElements/Button";
import SubmitModal from "../components/SubmitModal";

import "./SubmitFinal.css";

const SubmitFinal = (props) => {
  const auth = useContext(AuthContext);
  const { sendRequest, setError } = useContext(HttpContext);

  const [participationFee, setParticipationFee] = useState([
    {
      rowTitle: "참가자(팀)",
      // individual: 3,
      // teamSparring: 0,
      // teamForm: 0,
      // pairForm: 0,
      // sum: 3,
    },
    {
      rowTitle: "참가비(원)",
    },
  ]);
  const [participantApplicationInfos, setParticipantApplicationInfos] =
    useState({});

  const [representativeInfo, setRepresentativeInfo] = useState([
    { userName: "", universityName: "", phoneNumber: "", depositorName: "" },
  ]);
  const [depositInfo, setDepositInfo] = useState([{}]);
  const [submitModalShow, setSubmitModalShow] = useState(false);

  const formatParticipationFee = (applicationInfos) => {
    let result = [{ rowTitle: "참가자(팀)" }, { rowTitle: "참가비(원)" }];
    // TABLE_COLUMNS_SUBMIT_FEE 여기서 찾아서 맵핑 넣기
    applicationInfos.forEach((applicationInfo) => {
      const event = TABLE_COLUMNS_SUBMIT_FEE.find(
        (col) => col.name === applicationInfo.eventName
      );
      // event.id; // 이게 individual, teamSparring
      result[0][event.id] = applicationInfo.participantCount;
      result[1][event.id] = applicationInfo.participantFee;
    });

    return result;
  };

  const inputHandler = (id, val) => {
    const idArr = id.split("-");
    setRepresentativeInfo((prevValue) => {
      return [
        {
          ...prevValue[0],
          [idArr[2]]: val,
        },
      ];
    });
  };

  const firstPeriodInfoHandler = useCallback(async () => {
    try {
      const apiURL = props.isFinalSubmitted
        ? `${process.env.REACT_APP_BACKEND_URL}/api/user/after-final-submit/first-period?userId=${auth.userId}`
        : `${process.env.REACT_APP_BACKEND_URL}/api/user/final-submit/first-period-cost-info?userId=${auth.userId}`;

      const responseData = await sendRequest(
        apiURL,
        "GET",
        null,
        {
          Authorization: `Bearer ${auth.token}`,
        },
        "1차 정보 로드 실패"
      );

      if (responseData.isSuccess) {
        setParticipantApplicationInfos(
          responseData.payload.participantApplicationInfos
        );
        setParticipationFee(
          formatParticipationFee(
            responseData.payload.participantApplicationInfos
              .participantApplicationInfos
          )
        );
        if (props.isFinalSubmitted) {
          setRepresentativeInfo([
            {
              ...responseData.payload.userInfo,
            },
          ]);
        } else {
          setRepresentativeInfo([
            {
              ...responseData.payload.userInfo,
              phoneNumber: "",
              depositorName: "",
            },
          ]);
        }
        setDepositInfo([{ ...responseData.payload.accountInfo }]);
      } else {
        setError({
          title: "데이터 로드 실패",
          detail: responseData.message,
        });
      }
    } catch (err) {}
  }, [auth.token, auth.userId, sendRequest, setError, props.isFinalSubmitted]);

  useEffect(() => {
    firstPeriodInfoHandler();
  }, [firstPeriodInfoHandler]);

  const submitHandler = async (event) => {
    event.preventDefault();

    if (
      !representativeInfo[0].phoneNumber ||
      !representativeInfo[0].depositorName
    ) {
      setError({
        title: "대표자 정보 확인",
        detail: "대표자 정보를 입력해주세요.",
      });
      return;
    }

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/final-submit/first-period`,
        "POST",
        JSON.stringify({
          userInfo: {
            userId: auth.userId,
            phoneNumber: representativeInfo[0].phoneNumber,
            depositorName: representativeInfo[0].depositorName,
          },
          participantApplicationInfos: participantApplicationInfos,
        }),
        {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
        "최종제출 실패"
      );

      if (responseData.isSuccess) {
        setSubmitModalShow(true);
      }
    } catch (err) {}
  };

  const closeModal = () => {
    setSubmitModalShow(false);
  };

  const logoutHandler = () => {
    auth.logout();
  };

  const checkSubmitHandler = () => {
    props.setIsSubmitted(true);
    props.toFinalPage(false);
  };

  return (
    <div className="submit-final">
      <SubmitModal
        show={submitModalShow}
        onClear={closeModal}
        logoutHandler={logoutHandler}
        checkSubmitHandler={checkSubmitHandler}
        detail="결제 완료 시 1차 수정이 불가능합니다."
      />
      <h2 className="submit-title">최종 제출</h2>
      <div className="submit-final-element">
        <h4>참가비 정보</h4>
        <SubmitTable
          tableId="fee-table"
          columns={TABLE_COLUMNS_SUBMIT_FEE}
          data={participationFee}
        />
        <h4>대표자 정보</h4>
        <SubmitTable
          tableId="rep-table"
          columns={
            props.isFinalSubmitted
              ? TABLE_COLUMNS_REPRESENTATIVE_CHECK
              : TABLE_COLUMNS_REPRESENTATIVE_REGIST
          }
          data={representativeInfo}
          inputHandler={inputHandler}
        />
        <h4>입금 정보</h4>
        <SubmitTable
          tableId="dep-table"
          columns={TABLE_COLUMNS_DEPOSIT_INFO}
          data={depositInfo}
        />
      </div>
      {!props.isFinalSubmitted && (
        <Button className="submit-btn" onClick={submitHandler} type="button">
          제출 완료하기
        </Button>
      )}
    </div>
  );
};

export default SubmitFinal;
