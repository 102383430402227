// - 개인전(1,2,3,4)
//     - 개인전 여자 겨루기 1
//     - 개인전 여자 품새 2
//     - 개인전 남자 겨루기 3
//     - 개인전 남자 품새 4
// - 단체전(5,6,7,8)
//     - 단체전 여자 겨루기 5
//     - 단체전 여자 품새 6
//     - 단체전 남자 겨루기 7
//     - 단체전 남자 품새 8
// - 혼성(9)
// - 세컨(10)
// - 자원봉사자(11)

export const EVENT_ID = {
  "개인전 여성 겨루기": { id: 1 },
  "개인전 여성 품새": { id: 2 },
  "개인전 남성 겨루기": { id: 3 },
  "개인전 남성 품새": { id: 4 },
  "단체전 여성 겨루기": { id: 5, name: "겨루기 여성" },
  "단체전 여성 품새": { id: 6, name: "품새 여성" },
  "단체전 남성 겨루기": { id: 7, name: "겨루기 남성" },
  "단체전 남성 품새": { id: 8, name: "품새 남성" },
  "단체전 혼성 품새": { id: 9, name: "품새 페어" },
  세컨: { id: 10 },
  자원봉사자: { id: 11 },
};

export const WEIGHT_ID = {
  // 핀(1)/플라이(2)/벤텀(3)/페더(4)/라이트(5)/웰터(6)/미들(7)/헤비(8)/미들헤비(9)
  남성: {
    핀: { id: 1, maxWeight: 54 },
    플라이: { id: 2, maxWeight: 58 },
    벤텀: { id: 3, maxWeight: 63 },
    페더: { id: 4, maxWeight: 68 },
    라이트: { id: 5, maxWeight: 74 },
    웰터: { id: 6, maxWeight: 80 },
    미들: { id: 7, maxWeight: 87 },
    헤비: { id: 8, maxWeight: 91 },
  },
  여성: {
    핀: { id: 1, maxWeight: 46 },
    플라이: { id: 2, maxWeight: 49 },
    벤텀: { id: 3, maxWeight: 53 },
    페더: { id: 4, maxWeight: 57 },
    라이트: { id: 5, maxWeight: 62 },
    웰터: { id: 6, maxWeight: 67 },
    미들헤비: { id: 9, maxWeight: 81 },
  },
};
