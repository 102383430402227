import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import { AuthContext } from "./shared/context/auth-context";
import { HttpContext } from "./shared/context/http-context";
import { useAuth } from "./shared/hooks/auth-hook";
import { useHttpClient } from "./shared/hooks/http-hook";

import ErrorModal from "./shared/components/UIElements/ErrorModal";
import LoadingSpinner from "./shared/components/UIElements/LoadingSpinner";
import MainNavigation from "./shared/components/Navigation/MainNavigation";
import Login from "./auth/pages/Login";
import RegistMain from "./user/regist/pages/RegistMain";
import RegistIndividual from "./user/regist/pages/RegistIndividual";
import RegistTeam from "./user/regist/pages/RegistTeam";
import RegistSecond from "./user/regist/pages/RegistSecond";
import RegistVolunteer from "./user/regist/pages/RegistVolunteer";
import DocuSubmit from "./user/docu/pages/DocuSubmit";
import SubmitMain from "./user/submit/pages/SubmitMain";
import AdminMain from "./admin/pages/AdminMain";
import FirstUniversity from "./admin/pages/FirstUniversity";
import FirstEvent from "./admin/pages/FirstEvent";
import SecondUniversity from "./admin/pages/SecondUniversity";
import SecondEvent from "./admin/pages/SecondEvent";
import DocuView from "./admin/pages/DocuView";
import PersonalInfoProtectionModal from "./shared/components/UIElements/PersonalInfoProtectionModal";

const App = () => {
  const {
    isLoading,
    error,
    sendRequest,
    sendRequestFile,
    setError,
    clearError,
  } = useHttpClient();
  const {
    token,
    login,
    logout,
    userId,
    isAdmin,
    isFirstLogin,
    tokenExpirationDate,
    period,
    universityName,
  } = useAuth(sendRequest);
  const [personalInfoProtectionModalShow, setPersonalInfoProtectionModalShow] =
    useState(false);

  const closeModal = () => {
    setPersonalInfoProtectionModalShow(false);
  };

  const openModal = () => {
    setPersonalInfoProtectionModalShow(true);
  };

  let routes;
  let isLogin = !!token && !isFirstLogin;

  if (isLogin) {
    if (isAdmin) {
      // 관리자
      routes = (
        <Switch>
          <Route path="/" exact>
            <Redirect to="/admin/main" />
          </Route>
          <Route path="/admin/main" exact component={AdminMain}></Route>
          <Route path="/admin/docu" exact component={DocuView}></Route>
          <Route path="/admin/first" exact>
            <Redirect to="/admin/first/university" />
          </Route>
          <Route
            path="/admin/first/university"
            exact
            component={FirstUniversity}
          />
          <Route path="/admin/first/event" exact component={FirstEvent} />
          <Route path="/admin/second" exact>
            <Redirect to="/admin/second/university" />
          </Route>
          <Route
            path="/admin/second/university"
            exact
            component={SecondUniversity}
          />
          <Route path="/admin/second/event" exact component={SecondEvent} />
          <Redirect to="/" />
        </Switch>
      );
    } else {
      // 일반 대표자
      routes = (
        <Switch>
          <Route path="/" exact>
            <Redirect to="/regist" />
          </Route>
          <Route path="/regist" exact component={RegistMain} />
          <Route path="/regist/individual" exact component={RegistIndividual} />
          <Route path="/regist/team" exact component={RegistTeam} />
          <Route path="/regist/second" exact component={RegistSecond} />
          <Route path="/regist/volunteer" exact component={RegistVolunteer} />

          <Route path="/docu" exact component={DocuSubmit}></Route>

          <Route path="/submit" exact component={SubmitMain}></Route>
          <Redirect to="/" />
        </Switch>
      );
    }
  } else {
    routes = (
      <Switch>
        <Route path="/" exact>
          <Redirect to="/login" />
        </Route>
        <Route path="/login" exact>
          <Login />
        </Route>
        <Redirect to="/login" />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        login: login,
        logout: logout,
        isAdmin: isAdmin,
        isFirstLogin: isFirstLogin,
        tokenExpirationDate: tokenExpirationDate,
        period: period,
        universityName: universityName,
      }}
    >
      <HttpContext.Provider
        value={{
          isLoading,
          error,
          sendRequest,
          sendRequestFile,
          setError,
          clearError,
        }}
      >
        <ErrorModal
          title={error.title}
          error={error.detail}
          onClear={clearError}
        />
        <PersonalInfoProtectionModal
          show={personalInfoProtectionModalShow}
          onClear={closeModal}
        />
        {isLoading && <LoadingSpinner asOverlay />}
        <Router>
          {isLogin && <MainNavigation />}
          {/* {<MainNavigation />} */}
          <main>{routes}</main>
          <footer id="main-footer" onClick={openModal}>
            개인정보처리방침 및 이용약관
          </footer>
        </Router>
      </HttpContext.Provider>
    </AuthContext.Provider>
  );
};

export default App;
