import { useState, useCallback, useEffect } from "react";

let logoutTimer;

export const useAuth = (sendRequest) => {
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userId, setUserId] = useState(false);
  const [universityName, setUniversityName] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [period, setPeriod] = useState("NONE");

  const login = useCallback(
    (uid, token, userAuth, isFirstLogin, universityName) => {
      setUserId(uid);
      setIsAdmin(userAuth === "ADMIN" ? true : false);
      setIsFirstLogin(isFirstLogin);
      setUniversityName(universityName);

      let expirationDate = new Date();
      let tokenExpirationDate = new Date();

      const storedData = JSON.parse(localStorage.getItem("userData"));

      if (storedData && storedData.tokenExpirationDate) {
        tokenExpirationDate = new Date(storedData.tokenExpirationDate);

        if (
          userAuth !== "ADMIN" &&
          period === "FIRST" &&
          (new Date().getTime() <
            new Date(process.env.REACT_APP_PERIOD_FIRST_START).getTime() ||
            new Date().getTime() >
              new Date(process.env.REACT_APP_PERIOD_FIRST_END).getTime())
        ) {
          tokenExpirationDate = new Date();
        }

        if (
          userAuth !== "ADMIN" &&
          period === "SECOND" &&
          (new Date().getTime() <
            new Date(process.env.REACT_APP_PERIOD_SECOND_START).getTime() ||
            new Date().getTime() >
              new Date(process.env.REACT_APP_PERIOD_SECOND_END).getTime())
        ) {
          tokenExpirationDate = new Date();
        }
      } else if (userAuth === "ADMIN") {
        tokenExpirationDate = new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24 * 3
        ); // 3 day
      } else {
        // expirationDate =
        //   period === "FIRST"
        //     ? new Date(process.env.REACT_APP_PERIOD_FIRST_END)
        //     : period === "SECOND"
        //     ? new Date(process.env.REACT_APP_PERIOD_SECOND_END)
        //     : // : new Date(new Date().getTime() - 1000 * 1);
        //       new Date(new Date().getTime());

        // start 랑 end 사이에 없으면 토큰 없게
        if (
          period === "FIRST" &&
          new Date().getTime() >=
            new Date(process.env.REACT_APP_PERIOD_FIRST_START).getTime() &&
          new Date().getTime() <=
            new Date(process.env.REACT_APP_PERIOD_FIRST_END).getTime()
        ) {
          expirationDate = new Date(process.env.REACT_APP_PERIOD_FIRST_END);
        }

        if (
          period === "SECOND" &&
          new Date().getTime() >=
            new Date(process.env.REACT_APP_PERIOD_SECOND_START).getTime() &&
          new Date().getTime() <=
            new Date(process.env.REACT_APP_PERIOD_SECOND_END).getTime()
        ) {
          expirationDate = new Date(process.env.REACT_APP_PERIOD_SECOND_END);
        }

        tokenExpirationDate = new Date(
          Math.min(
            expirationDate.getTime(),
            new Date().getTime() + 1000 * 60 * 60 * 2
          )
        );
      }

      setTokenExpirationDate(tokenExpirationDate);
      setToken(token);
      localStorage.setItem(
        "userData",
        JSON.stringify({
          token: token,
          tokenExpirationDate: tokenExpirationDate,
        })
      );
    },
    [period]
  );

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    localStorage.removeItem("userData");
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(() => {
        logout();
        alert("토큰 시간이 만료되어 로그아웃 되었습니다. 재로그인해주세요.");
      }, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    // PERIOD 설정
    const fetchPeriod = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/env/period`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
          },
          "기간 정보 가져오기 실패"
        );

        setPeriod(responseData.payload.period);
      } catch (err) {}
    };

    const fetchUserData = async (storedData) => {
      try {
        // token 통해 백으로부터 user 정보 가져옴
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/env/user`,
          "GET",
          null,
          {
            Authorization: `Bearer ${storedData.token}`,
            "Content-Type": "application/json",
          },
          "사용자 정보 가져오기 실패"
        );

        if (responseData && ["FIRST", "SECOND"].includes(period)) {
          login(
            responseData.payload.userId,
            storedData.token,
            responseData.payload.userAuth,
            responseData.payload.isFirstLogin,
            responseData.payload.universityName
          );
        }
      } catch (err) {}
    };

    fetchPeriod();
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (storedData && storedData.token) {
      fetchUserData(storedData);
    }
  }, [login, sendRequest, period]);

  return {
    token,
    login,
    logout,
    userId,
    isAdmin,
    isFirstLogin,
    tokenExpirationDate,
    period,
    universityName,
  };
};
