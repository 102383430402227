import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { HttpContext } from "../../../shared/context/http-context";
import { AuthContext } from "../../../shared/context/auth-context";
import SubmitCheck from "./SubmitCheck";
import SubmitFinal from "./SubmitFinal";
import SubmitFinalSecond from "./SubmitFinalSecond";

const SubmitMain = () => {
  const history = useHistory();

  const auth = useContext(AuthContext);
  const { sendRequest, setError } = useContext(HttpContext);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDeposited, setIsDeposited] = useState(false);
  const [pageToFinal, setPageToFinal] = useState(false);

  useEffect(() => {
    // 전체서류제출 했는지 체크
    const checkDocumentSubmit = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/is-file-completed?userId=${auth.userId}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
          },
          `전체서류제출 확인 실패`
        );
        // const responseData = {
        //   isSuccess: true,
        // };

        // if (!responseData.isSuccess || !responseData.payload.fileCompleted) {
        if (!responseData.isSuccess) {
          setError({
            title: "서류 제출 확인",
            detail: "등록자 전원 서류 제출 완료했는지 확인해주세요.",
          });
          history.push({
            pathname: "/docu",
          });
        }
      } catch (error) {
        history.push({
          pathname: "/docu",
        });
      }
    };

    checkDocumentSubmit();

    //학교신청종목테이블 값 있는지없는지 -> 1페이지
    const checkIsSumitted = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/final-submit/is-final-submit?userId=${auth.userId}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
          },
          `최종제출여부 조회 실패`
        );

        // const responseData = {
        //   payload: {
        //     isFinalSubmitted: true,
        //   },
        // };

        if (responseData.isSuccess) {
          setIsSubmitted(responseData.payload.finalSubmitted);
          setIsDeposited(responseData.payload.isDepositConfirmed);
        }
      } catch (error) {}
    };
    checkIsSumitted();
  }, [auth.token, auth.userId, sendRequest, setError, history]);

  // 1차 2차 none auth.period
  // if (auth.period === "NONE") {
  //   return;
  // }

  const toFinalPage = (param) => {
    setPageToFinal(param);
  };

  //미결제 결제완료인지 -> 2페이지
  // 2차 때는 메뉴 이름도 환불 및 최종 제출로 바꿔야 할듯?

  return pageToFinal ? (
    auth.period === "FIRST" ? (
      <SubmitFinal
        isFinalSubmitted={isSubmitted}
        toFinalPage={toFinalPage}
        setIsSubmitted={setIsSubmitted}
      />
    ) : auth.period === "SECOND" ? (
      <SubmitFinalSecond
        isFinalSubmitted={isSubmitted}
        toFinalPage={toFinalPage}
        setIsSubmitted={setIsSubmitted}
      />
    ) : (
      <React.Fragment></React.Fragment>
    )
  ) : (
    <SubmitCheck
      toFinalPage={toFinalPage}
      isFinalSubmitted={isSubmitted}
      isDeposited={isDeposited}
    />
  );
};

export default SubmitMain;
