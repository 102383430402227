import React, { useState } from "react";

import { colInfo } from "../../shared/util/docu/docu-columns";

import SearchCondition from "../components/DocuView/SearchCondition";
import DocuElements from "../components/DocuView/DocuElements";

import "./DocuView.css";

const DocuView = () => {
  const docuCategories = ["전체", ...colInfo[4].columns.map((col) => col.name)];
  const [docuCategoryValue, setDocuCategoryValue] = useState(docuCategories[0]);

  const conditionOpens = {
    "조건 없음": { value: "all" },
    "열람 파일만": { value: "true" },
    "열람하지 않은 파일만": { value: "false" },
  };
  const conditionStranges = {
    "조건 없음": { value: "all" },
    "이상 파일만": { value: "true" },
    "이상하지 않은 파일만": { value: "false" },
  };

  const [conditionOpenValue, setConditionOpenValue] = useState(
    Object.keys(conditionOpens)[0]
  );
  const [conditionStrangeValue, setConditionStrangeValue] = useState(
    Object.keys(conditionStranges)[0]
  );
  const [searchValue, setSearchValue] = useState("");

  const inputDocuCategoryHandler = (id, val) => {
    setDocuCategoryValue(val);
  };
  const inputOpenConditionHandler = (id, val) => {
    setConditionOpenValue(val);
  };
  const inputStrangeConditionHandler = (id, val) => {
    setConditionStrangeValue(val);
  };
  const inputHandler = (val) => {
    setSearchValue(val);
  };

  return (
    <div className="docu-view">
      <h2 className="docu-view_title">서류 일괄 보기</h2>
      <SearchCondition
        docuCategories={docuCategories}
        conditionOpens={conditionOpens}
        conditionStranges={conditionStranges}
        searchValue={searchValue}
        docuCategoryValue={docuCategoryValue}
        conditionOpenValue={conditionOpenValue}
        conditionStrangeValue={conditionStrangeValue}
        inputDocuCategoryHandler={inputDocuCategoryHandler}
        inputOpenConditionHandler={inputOpenConditionHandler}
        inputStrangeConditionHandler={inputStrangeConditionHandler}
        inputHandler={inputHandler}
      />
      <DocuElements
        searchValue={searchValue}
        docuCategoryValue={docuCategoryValue}
        conditionOpenValue={conditionOpens[conditionOpenValue].value}
        conditionStrangeValue={conditionStranges[conditionStrangeValue].value}
      />
    </div>
  );
};

export default DocuView;
