import { useCallback, useReducer } from "react";

const registReducer = (state, action) => {
  switch (action.type) {
    case "INPUT_CHANGE":
      let idArr = action.inputId.split("-");
      let row = Number(idArr[0].replace("row", ""));

      // Create a new copy of the data array
      let data = [...state.inputs];

      // Update the specific row immutably
      let updatedRow = { ...data[row] };

      if (idArr.length === 3) {
        updatedRow[idArr[2]] = action.value;

        if (idArr[2] === "foreigner" && action.value.length === 0) {
          updatedRow["nationality"] = "";
        }
      } else if (idArr.length === 4) {
        // idnumber 같은 배열인 애들
        let subidx = Number(idArr[3].replace("input", ""));
        let updatedSubArray = [...updatedRow[idArr[2]]];
        updatedSubArray[subidx] = action.value;
        updatedRow[idArr[2]] = updatedSubArray;
      }

      // Assign the updated row back to the data array
      data[row] = updatedRow;

      return {
        ...state,
        inputs: data,
      };
    case "INPUT_CHANGE_TEAM":
      let idArrTeam = action.inputId.split("-");
      let teamIdx = Number(idArrTeam[0].replace("team", ""));
      let memberIdx = Number(idArrTeam[1].replace("row", ""));

      let dataTeam = [...state.inputs];
      let updatedTeamRow = { ...dataTeam[teamIdx] };
      updatedTeamRow.teamMembers = updatedTeamRow.teamMembers || [];
      let updatedTeamMembers = [...updatedTeamRow["teamMembers"]];
      let updatedTeamMember = { ...updatedTeamMembers[memberIdx] };

      if (idArrTeam.length === 4) {
        updatedTeamMember[idArrTeam[3]] = action.value;
        if (idArrTeam[3] === "foreigner" && action.value.length === 0) {
          updatedTeamMember["nationality"] = "";
        }
      }
      if (idArrTeam.length === 5) {
        let subidx = Number(idArrTeam[4].replace("input", ""));
        //
        if (updatedTeamMember[idArrTeam[3]]) {
          updatedTeamMember[idArrTeam[3]][subidx] = action.value;
        }
      }

      updatedTeamMembers[memberIdx] = updatedTeamMember;
      updatedTeamRow["teamMembers"] = updatedTeamMembers;
      dataTeam[teamIdx] = updatedTeamRow;

      return {
        ...state,
        inputs: dataTeam,
      };

    case "ADD_ROW":
      let addRow = [...state.inputs];
      const newRow = JSON.parse(JSON.stringify(action.value));
      addRow.push(newRow);

      return {
        ...state,
        inputs: addRow,
      };

    case "DELETE_ROW":
      let dataDelete = [...state.inputs];
      dataDelete.splice(action.row, 1);

      return {
        ...state,
        inputs: dataDelete,
      };

    case "SET_DATA":
      return {
        ...state,
        inputs: [...action.inputs],
      };

    case "TOGGLE_EDITABLE":
      // Update the specific row immutably
      let dataToggle = [...state.inputs];
      let updatedToggle = { ...dataToggle[action.row] };

      updatedToggle.editable = !updatedToggle.editable;

      //foreigner 처리 Y->['외국인], N->[]
      if (updatedToggle.foreigner !== undefined) {
        updatedToggle.foreigner =
          updatedToggle.foreigner[0] === "Y"
            ? ["외국인"]
            : updatedToggle.foreigner[0] === "N"
            ? []
            : updatedToggle.foreigner[0] === "외국인"
            ? ["Y"]
            : ["N"];
      }

      dataToggle[action.row] = updatedToggle;

      return {
        ...state,
        inputs: dataToggle,
      };

    default:
      return state;
  }
};

export const useRegist = (initialInputs, defaultInputs) => {
  const [registState, dispatch] = useReducer(registReducer, {
    inputs: initialInputs,
  });

  const inputHandler = useCallback((id, value, isValid, teamId) => {
    if (teamId) {
      dispatch({
        type: "INPUT_CHANGE_TEAM",
        value: value,
        isValid: isValid,
        inputId: id,
      });
    } else {
      dispatch({
        type: "INPUT_CHANGE",
        value: value,
        isValid: isValid,
        inputId: id,
      });
    }
  }, []);

  const addRow = useCallback(
    (input) => {
      dispatch({
        type: "ADD_ROW",
        value: input ? input : defaultInputs,
      });
    },
    [defaultInputs]
  );

  const deleteRow = useCallback((row) => {
    dispatch({
      type: "DELETE_ROW",
      row: row,
    });
  }, []);

  const setRegistData = useCallback((inputData) => {
    dispatch({
      type: "SET_DATA",
      inputs: inputData,
    });
  }, []);

  const toggleEditableRow = useCallback((row) => {
    dispatch({
      type: "TOGGLE_EDITABLE",
      row: row,
    });
  }, []);

  return [
    registState,
    inputHandler,
    addRow,
    deleteRow,
    setRegistData,
    toggleEditableRow,
  ];
};
