export const TABLE_COLUMNS_FIRST_UNIVERSITY = [
  { id: "universityName", name: "학교명", type: "text" },
  { id: "username", name: "대표자명", type: "text" },
  { id: "phoneNumber", name: "연락처", type: "text" },
  { id: "participantCount", name: "선수", type: "text" },
  { id: "secondCount", name: "세컨", type: "text" },
  {
    id: "totalParticipantCount",
    name: "참여인원(자원봉사자 제외)",
    type: "text",
  },
  { id: "volunteerCount", name: "자원봉사자", type: "text" },
  { id: "depositAmount", name: "입금금액", type: "text" },
  { id: "depositorName", name: "입금자명", type: "text" },
  {
    id: "depositConfirmed",
    name: "입금확인",
    type: "button",
    detail: {
      content: "입금확인",
    },
  },
];

export const TABLE_COLUMNS_EVENT_INDIVIDUAL = [
  { id: "name", name: "성명", type: "text" },
  { id: "universityName", name: "학교", type: "text" },
  { id: "gender", name: "성별", type: "text" },
  { id: "isForeigner", name: "외국인", type: "text" },
  { id: "nationality", name: "국적", type: "text" },
  { id: "identityNumber", name: "주민등록번호", type: "text" },
  { id: "phoneNumber", name: "비고", type: "text" },
];

export const TABLE_COLUMNS_EVENT_TEAM = [
  { id: "universityName", name: "학교", type: "text" },
  { id: "eventTeamNumber", name: "팀번호", type: "text" },
  { id: "indexInTeam", name: "팀내번호", type: "text" },
  { id: "name", name: "성명", type: "text" },
  { id: "gender", name: "성별", type: "text" },
  { id: "isForeigner", name: "외국인", type: "text" },
  { id: "nationality", name: "국적", type: "text" },
  { id: "identityNumber", name: "주민등록번호", type: "text" },
  { id: "weight", name: "체급(겨루기만)", type: "text" },
  { id: "phoneNumber", name: "비고", type: "text" },
];

export const TABLE_COLUMNS_EVENT_SECOND = [
  { id: "name", name: "성명", type: "text" },
  { id: "universityName", name: "학교", type: "text" },
  { id: "gender", name: "성별", type: "text" },
  { id: "isForeigner", name: "외국인", type: "text" },
  { id: "nationality", name: "국적", type: "text" },
  { id: "identityNumber", name: "주민등록번호", type: "text" },
  { id: "phoneNumber", name: "비고", type: "text" },
];

export const TABLE_COLUMNS_EVENT_VOLUNTEER = [
  { id: "name", name: "성명", type: "text" },
  { id: "universityName", name: "학교", type: "text" },
  { id: "gender", name: "성별", type: "text" },
  { id: "phoneNumber", name: "전화번호", type: "text" },
];

export const TABLE_COLUMNS_SECOND_UNIVERSITY = [
  { id: "universityName", name: "학교명", type: "text" },
  { id: "userName", name: "대표자명", type: "text" },
  { id: "phoneNumber", name: "연락처", type: "text" },
  { id: "participantCount", name: "선수", type: "text" },
  { id: "secondCount", name: "세컨", type: "text" },
  {
    id: "totalParticipantCount",
    name: "참여인원(자원봉사자 제외)",
    type: "text",
  },
  { id: "volunteerCount", name: "자원봉사자", type: "text" },
  { id: "refundAmount", name: "환불금액", type: "text" },
  { id: "depositOwnerName", name: "예금주명", type: "text" },
  { id: "refundBank", name: "환불은행", type: "text" },
  { id: "refundAccount", name: "환불계좌", type: "text" },
  {
    id: "refundConfirmed",
    name: "환불확인",
    type: "button",
    detail: {
      content: "환불확인",
    },
  },
];
