import React, { useState } from "react";

import SearchIcon from "@material-ui/icons/Search";

import { VALIDATOR_REQUIRE } from "../../../shared/util/validators";

import Dropdown from "../../../shared/components/TableInputElements/Dropdown";
import Input from "../../../shared/components/TableInputElements/Input";
import Button from "../../../shared/components/TableInputElements/Button";

const SearchCondition = (props) => {
  const [searchValue, setSearchValue] = useState("");

  const inputEnterHandler = (e) => {
    e.persist();
    if (e.key === "Enter") {
      props.inputHandler(searchValue);
    }
  };

  return (
    <div className="dropdown-items">
      <div className="dropdown-items-left">
        <div className="dropdown-item">
          <div className="dropdown-title">서류 종류</div>
          <Dropdown
            id="category"
            items={props.docuCategories}
            onInput={props.inputDocuCategoryHandler}
            initialValue={props.docuCategoryValue}
          />
        </div>
        <div className="dropdown-item">
          <div className="dropdown-title">열람 조건</div>
          <Dropdown
            id="categoryOpen"
            items={Object.keys(props.conditionOpens)}
            onInput={props.inputOpenConditionHandler}
            initialValue={props.conditionOpenValue}
          />
        </div>
        <div className="dropdown-item">
          <div className="dropdown-title">이상 조건</div>
          <Dropdown
            id="categoryStrange"
            items={Object.keys(props.conditionStranges)}
            onInput={props.inputStrangeConditionHandler}
            initialValue={props.conditionStrangeValue}
          />
        </div>
      </div>
      <div className="dropdown-items-right">
        <div className="dropdown-item">
          <div className="dropdown-title">검색</div>
          <div className="search-wrap">
            <Input
              element="input"
              // ref={inputUniname}
              id="search"
              type="text"
              placeholder="검색(학교명,이름)"
              validators={[VALIDATOR_REQUIRE()]}
              // onInput={props.inputHandler}
              onInput={(id, val) => {
                setSearchValue(val);
              }}
              keyDownHandler={inputEnterHandler}
              initialValue={searchValue}
            />
            <Button
              onClick={() => {
                props.inputHandler(searchValue);
              }}
            >
              <SearchIcon fontSize="small" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchCondition;
