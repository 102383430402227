import React, { useCallback } from "react";

import {
  TABLE_COLUMNS_REGIST_INDIVIDUAL,
  TABLE_COLUMNS_REGIST_PERIOD2_INDIVIDUAL,
  TABLE_COLUMNS_CHECK_INDIVIDUAL,
} from "../../../shared/util/regist/regist-columns";
import { checkValidityIndividual } from "../../../shared/util/regist/regist-validators";
import { formatIndividual } from "../../../shared/util/format";

import RegistFormat from "../components/RegistFormat";

const RegistIndividual = () => {
  const newPersonFormat = useCallback(
    {
      name: "",
      sex: "",
      foreigner: [],
      nationality: "",
      idnumber: ["", "-", ""],
      event: [],
      weight: "",
      phoneNumber: "",
      isNew: true,
    },
    []
  );

  return (
    <RegistFormat
      koreanTitle="개인전"
      englishTitle="individual"
      personName="선수"
      registTableColumn={TABLE_COLUMNS_REGIST_INDIVIDUAL}
      registTableColumnSecondPeriod={TABLE_COLUMNS_REGIST_PERIOD2_INDIVIDUAL}
      checkTableColumn={TABLE_COLUMNS_CHECK_INDIVIDUAL}
      newPersonFormat={newPersonFormat}
      checkValidity={checkValidityIndividual}
      errMsgPersonName="개인전 선수"
      formatParticipant={formatIndividual}
    />
  );
};

export default RegistIndividual;
