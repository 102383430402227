import React from "react";

import "./SubmitTable.css";
import SubmitTableHeader from "./SubmitTableHeader";
import SubmitTableRow from "./SubmitTableRow";

const SubmitTable = (props) => {
  return (
    <table id={props.tableId} className="submit-table">
      <SubmitTableHeader
        showNumber={props.showNumber}
        columns={props.columns}
      />
      <tbody>
        {props.data.map((row, i) => (
          <SubmitTableRow
            key={i}
            rowData={row}
            rowIdx={i}
            showNumber={props.showNumber}
            cssGrayBackground={props.cssGrayBackground}
            columns={props.columns}
            inputHandler={props.inputHandler}
            buttonHandler={props.buttonHandler}
          />
        ))}
      </tbody>
    </table>
  );
};

export default SubmitTable;
