import React, { useCallback } from "react";

import {
  TABLE_COLUMNS_REGIST_SECOND,
  TABLE_COLUMNS_REGIST_PERIOD2_SECOND,
  TABLE_COLUMNS_CHECK_SECOND,
} from "../../../shared/util/regist/regist-columns";
import { checkValiditySecond } from "../../../shared/util/regist/regist-validators";
import { formatSecond } from "../../../shared/util/format";

import RegistFormat from "../components/RegistFormat";

const RegistSecond = () => {
  const newPersonFormat = useCallback(
    {
      name: "",
      sex: "",
      foreigner: [],
      nationality: "",
      idnumber: ["", "-", ""],
      phoneNumber: "",
      isNew: true,
    },
    []
  );

  return (
    <RegistFormat
      koreanTitle="세컨"
      englishTitle="second"
      personName="세컨"
      registTableColumn={TABLE_COLUMNS_REGIST_SECOND}
      registTableColumnSecondPeriod={TABLE_COLUMNS_REGIST_PERIOD2_SECOND}
      checkTableColumn={TABLE_COLUMNS_CHECK_SECOND}
      newPersonFormat={newPersonFormat}
      checkValidity={checkValiditySecond}
      errMsgPersonName="세컨"
      formatParticipant={formatSecond}
    />
  );
};

export default RegistSecond;
