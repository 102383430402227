import React, { useState, useContext, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import {
  TABLE_COLUMNS_CHECK_INDIVIDUAL,
  TABLE_COLUMNS_CHECK_TEAM,
  TABLE_COLUMNS_CHECK_SECOND,
  TABLE_COLUMNS_CHECK_VOLUNTEER,
} from "../../../shared/util/regist/regist-columns";
import { HttpContext } from "../../../shared/context/http-context";
import { AuthContext } from "../../../shared/context/auth-context";

import {
  formatIndividual,
  formatTeam,
  formatSecond,
  formatVolunteer,
} from "../../../shared/util/format";

import RegistTable from "../../regist/components/RegistTable";
import RegistTeamTable from "../../regist/components/RegistTeamTable";
import Button from "../../../shared/components/TableInputElements/Button";

import "./SubmitCheck.css";

const SubmitCheck = (props) => {
  const auth = useContext(AuthContext);
  const { sendRequest, setError } = useContext(HttpContext);

  const history = useHistory();

  const [participants, setParticipants] = useState({
    individual: [],
    team: [],
    second: [],
    volunteer: [],
  });
  const [teamIdx, setTeamIdx] = useState(0);

  const listHandler = useCallback(
    async (englishType, koreanType) => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/${englishType}?userId=${auth.userId}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          },
          `${koreanType} 로드 실패`
        );

        if (!responseData.isSuccess) {
          setError({
            title: `${koreanType} 데이터 불러오기 실패`,
            detail: responseData.message,
          });
        }

        switch (englishType) {
          case "individual":
            if (responseData.payload.isParticipantExists) {
              setParticipants((prevParticipants) => {
                const updatedParticipants = { ...prevParticipants };
                updatedParticipants.individual =
                  responseData.payload.participants.map((participant) =>
                    formatIndividual(participant, 1)
                  );
                return updatedParticipants;
              });
            }
            break;
          case "team":
            if (responseData.payload.isTeamExists) {
              setParticipants((prevParticipants) => {
                const updatedParticipants = { ...prevParticipants };
                updatedParticipants.team = responseData.payload.teams.map(
                  (team) => {
                    // 1,2,3,후보 순으로 정렬
                    team.teamMembers.sort(function (a, b) {
                      if (a.indexInTeam > b.indexInTeam) return 1;
                      if (a.indexInTeam < b.indexInTeam) return -1;
                      return 0;
                    });
                    return formatTeam(team, 1);
                  }
                );
                return updatedParticipants;
              });
            }
            break;
          case "second":
            if (responseData.payload.isParticipantExists) {
              setParticipants((prevParticipants) => {
                const updatedParticipants = { ...prevParticipants };
                updatedParticipants.second =
                  responseData.payload.participants.map((participant) =>
                    formatSecond(participant, 1)
                  );
                return updatedParticipants;
              });
            }
            break;
          case "volunteer":
            if (responseData.payload.isParticipantExists) {
              setParticipants((prevParticipants) => {
                const updatedParticipants = { ...prevParticipants };
                updatedParticipants.volunteer =
                  responseData.payload.participants.map((participant) =>
                    formatVolunteer(participant, 1)
                  );
                return updatedParticipants;
              });
            }
            break;
          default:
            break;
        }
      } catch (err) {}
    },
    [auth.token, auth.userId, sendRequest, setError]
  );

  const nextTeamHandler = (event) => {
    event.preventDefault();

    setTeamIdx((prevValue) => {
      if (prevValue < participants.team.length - 1) {
        return prevValue + 1;
      } else {
        return prevValue;
      }
    });
  };

  const previousTeamHandler = (event) => {
    event.preventDefault();

    setTeamIdx((prevValue) => {
      if (prevValue > 0) {
        return prevValue - 1;
      } else {
        return prevValue;
      }
    });
  };

  const modifyHandler = (event) => {
    event.preventDefault();
    history.push({ pathname: "/regist" });
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/is-file-completed?userId=${auth.userId}`,
        "GET",
        null,
        {
          Authorization: `Bearer ${auth.token}`,
        },
        `전체서류 제출정보 로드 실패`
      );

      if (responseData.isSuccess && responseData.payload.fileCompleted) {
        props.toFinalPage(true);
      } else {
        setError({
          title: "서류 제출 필요",
          detail: "모든 참가자의 서류를 제출해주셔야 최종 제출이 가능합니다.",
        });
        history.push({
          pathname: "/docu",
        });
      }
    } catch (err) {}
  };

  useEffect(() => {
    listHandler("individual", "개인전");
    listHandler("team", "단체전");
    listHandler("second", "세컨");
    listHandler("volunteer", "자원봉사자");
  }, [listHandler]);

  return (
    <div className="submit-check">
      <h2 className="submit-title">신청 확인</h2>
      <div id="individual-check" className="event-element">
        <h4>개인전({participants.individual.length}명)</h4>
        {participants.individual.length > 0 && (
          <RegistTable
            version="submit-check"
            columns={TABLE_COLUMNS_CHECK_INDIVIDUAL}
            checkColumns={TABLE_COLUMNS_CHECK_INDIVIDUAL}
            data={participants.individual}
            showNumber
          />
        )}
      </div>
      <div id="team-check" className="event-element">
        <h4>단체전({participants.team.length}팀)</h4>
        {participants.team[teamIdx] && (
          <div className="event-team-element">
            <div className="pageArrow">
              <ArrowLeftIcon
                htmlColor={teamIdx <= 0 ? "#e7e7e2" : ""}
                onClick={previousTeamHandler}
              />
              <ArrowRightIcon
                htmlColor={
                  teamIdx >= participants.team.length - 1 ? "#e7e7e2" : ""
                }
                onClick={nextTeamHandler}
              />
            </div>
            <div className="event-team-element-subtitle">
              <div>
                <span className="event-team-teamNumber">{teamIdx + 1}팀</span>
                {participants.team[teamIdx].event}
              </div>
            </div>
            <RegistTeamTable
              columns={TABLE_COLUMNS_CHECK_TEAM}
              data={participants.team[teamIdx].teamMembers}
            />
          </div>
        )}
      </div>
      <div id="second-check" className="event-element">
        <h4>세컨({participants.second.length}명)</h4>
        {participants.second.length > 0 && (
          <RegistTable
            version="submit-check"
            columns={TABLE_COLUMNS_CHECK_SECOND}
            checkColumns={TABLE_COLUMNS_CHECK_SECOND}
            data={participants.second}
            showNumber
          />
        )}
      </div>
      <div id="volunteer-check" className="event-element">
        <h4>자원봉사자({participants.volunteer.length}명)</h4>
        {participants.volunteer.length > 0 && (
          <RegistTable
            version="submit-check"
            columns={TABLE_COLUMNS_CHECK_VOLUNTEER}
            checkColumns={TABLE_COLUMNS_CHECK_VOLUNTEER}
            data={participants.volunteer}
            showNumber
          />
        )}
      </div>
      <div className="submit-check_btns">
        {!props.isFinalSubmitted && (
          <Button
            className="submit-check_btn"
            onClick={modifyHandler}
            type="button"
          >
            수정하기
          </Button>
        )}
        <Button
          className="submit-check_btn"
          onClick={submitHandler}
          type="button"
        >
          {props.isFinalSubmitted ? "최종제출 확인" : "최종 제출"}
        </Button>
      </div>
    </div>
  );
};

export default SubmitCheck;
