import React, { useCallback } from "react";

import {
  TABLE_COLUMNS_REGIST_VOLUNTEER,
  TABLE_COLUMNS_REGIST_PERIOD2_VOLUNTEER,
  TABLE_COLUMNS_CHECK_VOLUNTEER,
} from "../../../shared/util/regist/regist-columns";
import { checkValidityVolunteer } from "../../../shared/util/regist/regist-validators";
import { formatVolunteer } from "../../../shared/util/format";

import RegistFormat from "../components/RegistFormat";

const RegistVolunteer = () => {
  const newPersonFormat = useCallback(
    {
      name: "",
      sex: "",
      phoneNumber: ["", "-", "", "-", ""],
      isNew: true,
    },
    []
  );

  return (
    <RegistFormat
      koreanTitle="자원봉사자"
      englishTitle="volunteer"
      personName="자원봉사자"
      registTableColumn={TABLE_COLUMNS_REGIST_VOLUNTEER}
      registTableColumnSecondPeriod={TABLE_COLUMNS_REGIST_PERIOD2_VOLUNTEER}
      checkTableColumn={TABLE_COLUMNS_CHECK_VOLUNTEER}
      newPersonFormat={newPersonFormat}
      checkValidity={checkValidityVolunteer}
      errMsgPersonName="자원봉사자"
      formatParticipant={formatVolunteer}
    />
  );
};

export default RegistVolunteer;
