import React from "react";
import Modal from "../../../shared/components/UIElements/Modal";
import Button from "../../../shared/components/FormElements/Button";

import "./SubmitModal.css";

const SubmitModal = (props) => {
  return (
    <Modal
      id="submit-modal"
      onCancel={props.onClear}
      headerClass="modal__header-hide"
      show={props.show}
      footerClass="modal__footer-hide"
      className="event-select-modal"
    >
      <div className="modal__title">최종 제출이 완료되었습니다.</div>
      <div className="modal__detail">{props.detail}</div>
      <div className="modal-btns-wrap">
        <Button
          id="logout"
          className="event-select-modal__btn"
          onClick={props.logoutHandler}
        >
          로그아웃
        </Button>
        <Button
          id="logout"
          className="event-select-modal__btn"
          onClick={props.checkSubmitHandler}
        >
          최종신청 내역 확인
        </Button>
      </div>
    </Modal>
  );
};

export default SubmitModal;
