import React from "react";

import Button from "../../shared/components/FormElements/Button";

import "./AdminMain.css";

const AdminMain = () => {
  const menuBtns =
    new Date() < new Date(process.env.REACT_APP_PERIOD_SECOND_START) ? (
      <React.Fragment>
        <Button to="/admin/docu" exact inverse>
          서류 일괄보기
        </Button>
        <Button to="/admin/first" exact inverse>
          등록내역(1차)
        </Button>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Button to="/admin/docu" exact inverse>
          서류 일괄보기
        </Button>
        <Button to="/admin/second" exact inverse>
          최종내역(2차)
        </Button>
      </React.Fragment>
    );

  return (
    <div className="admin-main">
      <div className="admin-main_btn-wrap">{menuBtns}</div>
    </div>
  );
};

export default AdminMain;
