import React, { useState, useContext, useCallback, useEffect } from "react";

import { HttpContext } from "../../../shared/context/http-context";
import { AuthContext } from "../../../shared/context/auth-context";

import DocuElement from "./DocuElement";
import DocuViewModal from "./DocuViewModal";

import "./DocuElements.css";

const DocuElements = (props) => {
  const { sendRequest, sendRequestFile, setError } = useContext(HttpContext);
  const auth = useContext(AuthContext);

  const [docuInfos, setDocuInfos] = useState([]);
  const [docuViewModalShow, setDocuViewModalShow] = useState(false);
  const [curDocuInfo, setCurDocuInfo] = useState({
    index: [0, 0],
    fileInfo: {},
    fileData: "",
    contentType: "",
  });

  const docuListHandler = useCallback(async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/view-docs?category=${props.docuCategoryValue}&conditionOpen=${props.conditionOpenValue}&conditionStrange=${props.conditionStrangeValue}&search=${props.searchValue}`,
        "GET",
        null,
        {
          Authorization: `Bearer ${auth.token}`,
        },
        "서류리스트 로드 실패"
      );

      if (responseData.isSuccess) {
        // 정렬
        responseData.payload.forEach((docuArr) => {
          docuArr.sort((a, b) =>
            a.fileName < b.fileName ? -1 : a.fileName > b.fileName ? 1 : 0
          );
        });

        responseData.payload.sort((a, b) =>
          a[0].fileName < b[0].fileName
            ? -1
            : a[0].fileName > b[0].fileName
            ? 1
            : 0
        );

        setDocuInfos(responseData.payload);
      }
    } catch (err) {}
  }, [
    props.docuCategoryValue,
    props.conditionOpenValue,
    props.conditionStrangeValue,
    props.searchValue,
    auth.token,
    sendRequest,
  ]);

  const getFileHandler = useCallback(
    async (divId, fileId) => {
      const idArray = divId.split("-");

      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/admin/view-doc?fileId=${fileId}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          },
          "서류정보 로드 실패"
        );

        const responseImage = await sendRequestFile(
          `${process.env.REACT_APP_BACKEND_URL}/api/admin/view-image?fileId=${fileId}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          },
          "서류파일 로드 실패"
        );

        if (responseData.isSuccess) {
          setCurDocuInfo({
            ...responseData.payload,
            file: responseImage,
            index: [Number(idArray[1]), Number(idArray[2])],
          });
        }
      } catch (err) {}
    },
    [auth.token, sendRequest, sendRequestFile]
  );

  const putFileInfoHandler = useCallback(
    async (fileId, isFileOpened, isFileStrange) => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/admin/view-doc`,
          "POST",
          JSON.stringify({
            fileId: fileId,
            isFileOpened: isFileOpened,
            isFileStrange: isFileStrange,
          }),
          {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
          },
          "서류검토 실패"
        );

        if (!responseData.isSuccess) {
          setError({
            title: `서류검토 실패`,
            detail: responseData.message,
          });
        }
      } catch (err) {}
    },
    [auth.token, sendRequest, setError]
  );

  const rightHandler = useCallback(() => {
    if (
      curDocuInfo.index[0] === docuInfos.length - 1 &&
      curDocuInfo.index[1] === docuInfos[curDocuInfo.index[0]].length - 1
    ) {
      return;
    }

    const lineNum = docuInfos[curDocuInfo.index[0]].length;

    let idx = [0, 0];
    if (curDocuInfo.index[1] < lineNum - 1) {
      idx = [curDocuInfo.index[0], curDocuInfo.index[1] + 1];
    } else {
      idx = [curDocuInfo.index[0] + 1, 0];
    }

    getFileHandler(
      `docu-${idx[0]}-${idx[1]}`,
      docuInfos[idx[0]][idx[1]].fileId
    );
  }, [curDocuInfo, docuInfos, getFileHandler]);

  const leftHandler = useCallback(() => {
    if (curDocuInfo.index[0] === 0 && curDocuInfo.index[1] === 0) {
      return;
    }

    let idx = [0, 0];
    if (curDocuInfo.index[1] > 0) {
      idx = [curDocuInfo.index[0], curDocuInfo.index[1] - 1];
    } else {
      idx = [
        curDocuInfo.index[0] - 1,
        docuInfos[curDocuInfo.index[0] - 1].length - 1,
      ];
    }

    getFileHandler(
      `docu-${idx[0]}-${idx[1]}`,
      docuInfos[idx[0]][idx[1]].fileId
    );
  }, [curDocuInfo, docuInfos, getFileHandler]);

  const checkStrangeHandler = useCallback(
    (fileId, isFileStrange) => {
      putFileInfoHandler(fileId, true, isFileStrange);
      setCurDocuInfo((prevVal) => {
        return {
          ...prevVal,
          fileInfo: {
            ...prevVal.fileInfo,
            isFileStrange: isFileStrange,
          },
        };
      });
    },
    [putFileInfoHandler]
  );

  const openHandler = useCallback(
    (fileId, isFileStrange) => {
      putFileInfoHandler(fileId, true, isFileStrange);
    },
    [putFileInfoHandler]
  );

  useEffect(() => {
    docuListHandler();
  }, [docuListHandler]);

  const docuViewHandler = async (id, docuInfo) => {
    await getFileHandler(id, docuInfo.fileId);
    setDocuViewModalShow(true);
    // console.log(id, docuInfo);
    // console.log(curDocuInfo);
  };

  const closeModal = useCallback(() => {
    setDocuViewModalShow(false);
    docuListHandler();
  }, [docuListHandler]);

  // 모달 ON일 때 키보드 처리
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!docuViewModalShow) return; // 모달이 열려 있을 때만 작동

      switch (event.key) {
        case "ArrowRight":
          rightHandler();
          break;
        case "ArrowLeft":
          leftHandler();
          break;
        case " ":
          event.preventDefault();
          checkStrangeHandler(
            curDocuInfo.fileInfo.fileId,
            !curDocuInfo.fileInfo.isFileStrange
          );
          break;
        case "Escape":
          closeModal();
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    docuViewModalShow,
    rightHandler,
    leftHandler,
    checkStrangeHandler,
    curDocuInfo,
    closeModal,
  ]);

  return (
    <div className="docu-elements">
      <DocuViewModal
        show={docuViewModalShow}
        onClear={closeModal}
        docuInfo={curDocuInfo}
        rightHandler={rightHandler}
        leftHandler={leftHandler}
        checkStrangeHandler={checkStrangeHandler}
        openHandler={openHandler}
        noright={
          curDocuInfo.index[0] === docuInfos.length - 1 &&
          curDocuInfo.index[1] === docuInfos[curDocuInfo.index[0]].length - 1
        }
        noleft={curDocuInfo.index[0] === 0 && curDocuInfo.index[1] === 0}
      />
      <div className="docu-img-description">
        <div className="docu-icon_img">
          <img
            src={`${process.env.PUBLIC_URL}/img/default_folder.png`}
            width={"14px"}
            alt="아이콘 에러"
          />
        </div>
        <span className="icon-name">미열람 서류</span>
        <div className="docu-icon_img">
          <img
            src={`${process.env.PUBLIC_URL}/img/open_folder.png`}
            width={"14px"}
            alt="에러"
          />
        </div>
        <span>열람 서류</span>
        <div className="docu-icon_img">
          <img
            src={`${process.env.PUBLIC_URL}/img/dangerous_folder.png`}
            width={"14px"}
            alt="에러"
          />
        </div>
        <span>이상 서류</span>
      </div>
      {docuInfos.map((docuInfosLine, idx) => {
        return (
          <div key={`docuLine${idx}`} className="docu-elements-line">
            {docuInfosLine.map((docuInfo, i) => (
              <DocuElement
                key={`docu-${idx}-${i}`}
                id={`docu-${idx}-${i}`}
                docuInfo={docuInfo}
                onClick={docuViewHandler}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default DocuElements;
