import React from "react";

const DocuElement = (props) => {
  let imgName = null;
  if (
    props.docuInfo.isFileOpened === false
    // && props.docuInfo.isFileStrange === null
  )
    imgName = "default_folder.png";
  else if (
    props.docuInfo.isFileOpened === true &&
    (props.docuInfo.isFileStrange === null ||
      props.docuInfo.isFileStrange === false)
  )
    imgName = "open_folder.png";
  else if (
    props.docuInfo.isFileOpened === true &&
    props.docuInfo.isFileStrange === true
  )
    imgName = "dangerous_folder.png";

  const onClickHandler = () => {
    props.onClick(props.id, props.docuInfo);
  };

  return (
    <div className="docu-element" id={props.id}>
      <div className="docu-element_icon" onClick={onClickHandler}>
        <img
          src={`${process.env.PUBLIC_URL}/img/${imgName}`}
          width={"50px"}
          alt="에러"
        />
      </div>
      <div className="docu-element_docuName" onClick={onClickHandler}>
        {props.docuInfo.fileName.split("_")[2].split(".")[0]}
      </div>
      <div
        className="docu-element_personName"
        onClick={onClickHandler}
      >{`${props.docuInfo.universityName} ${props.docuInfo.participantName}`}</div>
    </div>
  );
};

export default DocuElement;
