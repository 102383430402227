import React, { useState, useContext, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import {
  TABLE_COLUMNS_CHECK_TEAM,
  TABLE_COLUMNS_REGIST_TEAM_SPARRING,
  TABLE_COLUMNS_REGIST_TEAM_FORM,
  TABLE_COLUMNS_REGIST_PERIOD2_TEAM_FORM,
  TABLE_COLUMNS_REGIST_PERIOD2_TEAM_SPARRING,
} from "../../../shared/util/regist/regist-columns";
import { WEIGHT_ID } from "../../../shared/util/const-event";
import { checkValidityTeam } from "../../../shared/util/regist/regist-validators";
import { formatTeam } from "../../../shared/util/format";
import { useRegist } from "../../../shared/hooks/regist-hook";
import { HttpContext } from "../../../shared/context/http-context";
import { AuthContext } from "../../../shared/context/auth-context";

import RegistTeamTable from "../components/RegistTeamTable";
import Button from "../../../shared/components/TableInputElements/Button";

import "./RegistTeam.css";
import AddTeamModal from "../components/AddTeamModal";

// popup 에서 누르면 addRow하고 member 숫자만큼 데이터 추가하기
// 단체전 별 member 숫자는 util에서 정해주는 걸루

const RegistTeam = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();

  const { sendRequest, setError } = useContext(HttpContext);

  const [isRegistMode, setIsRegistMode] = useState(false);
  const [apiFail, setApiFail] = useState(false);
  const [teamSelectModalShow, setTeamSelectModalShow] = useState(false);

  const [saveTeam, setSaveTeam] = useState([]);
  const [isEditable, setIsEditable] = useState(false);

  const errMsgPersonName = "팀";
  const englishTitle = "team";
  const checkValidity = (teamNum) => {
    const teamData = registState.inputs[teamNum];

    // each teamMember validity check
    const teamMemberNumber = teamData.teamMembers.length;
    const idNumArray = [];
    for (let i = 0; i < teamMemberNumber; i++) {
      if (teamData.teamMembers[i].editable) {
        const { result, message, focusCol } = checkValidityTeam(
          teamData.teamMembers[i],
          teamData.event
        );
        if (!result) {
          // 포커스 틀린 컬럼으로
          document.getElementById(`team${teamNum}-row${i}${focusCol}`).focus();
          return {
            isValidity: false,
            message: message,
            teamMemberIndex: teamData.teamMembers[i].indexInTeam,
          };
        }
        if (teamData.teamMembers[i].idnumber[0]) {
          idNumArray.push(
            teamData.teamMembers[i].idnumber[0] +
              teamData.teamMembers[i].idnumber[1] +
              teamData.teamMembers[i].idnumber[2]
          );
        } else {
          idNumArray.push(teamData.teamMembers[i].phoneNumber);
        }
      }
    }

    // 팀 내 주민등록번호 같지 않게 처리
    const idNumSet = new Set(idNumArray);
    if (idNumArray.length !== idNumSet.size) {
      return {
        isValidity: false,
        message: "동일한 선수 정보가 중복되어 입력되었습니다.",
      };
    }

    // 겨루기인 경우 단체전 체급 합산 체크
    if (teamData.event.includes("겨루기")) {
      const weights = teamData.teamMembers
        .filter((member) => member.editable)
        .map((member) => WEIGHT_ID[member.sex][member.weight].maxWeight);

      let sumWeightOK = true;
      if (weights.length > 3) {
        const sum = weights.reduce((a, b) => a + b, 0);
        const min = Math.min.apply(null, weights);
        if (teamData.event.includes("남성")) {
          if (
            sum > process.env.REACT_APP_TEAM_SPARRING_WEIGHT_MALE_MEM4_LIMIT ||
            sum - min >
              process.env.REACT_APP_TEAM_SPARRING_WEIGHT_MALE_MEM3_LIMIT
          ) {
            sumWeightOK = false;
          }
        } else {
          if (
            sum >
              process.env.REACT_APP_TEAM_SPARRING_WEIGHT_FEMALE_MEM4_LIMIT ||
            sum - min >
              process.env.REACT_APP_TEAM_SPARRING_WEIGHT_FEMALE_MEM3_LIMIT
          ) {
            sumWeightOK = false;
          }
        }
      } else {
        const sum = weights.reduce((a, b) => a + b, 0);
        if (teamData.event.includes("남성")) {
          if (
            sum > process.env.REACT_APP_TEAM_SPARRING_WEIGHT_MALE_MEM3_LIMIT
          ) {
            sumWeightOK = false;
          }
        } else {
          if (
            sum > process.env.REACT_APP_TEAM_SPARRING_WEIGHT_FEMALE_MEM3_LIMIT
          ) {
            sumWeightOK = false;
          }
        }
      }

      if (!sumWeightOK) {
        return {
          isValidity: false,
          message: "체중 조건을 맞춰주세요",
        };
      }
    }

    return { isValidity: true };
  };

  const [registState, inputHandler, addRow, deleteRow, setRegistData] =
    useRegist([], {
      eventTeamNumber: null,
      event: null,

      teamMembers: [],
    });

  const addTeamHandler = (event) => {
    event.preventDefault();
    // modal 띄우기
    setTeamSelectModalShow(true);
    // popup 에서 누르면 addRow하고 member 숫자만큼 데이터 추가하기(util 파라미터 이용)
  };

  const closeSelectModal = (event) => {
    event.preventDefault();
    setTeamSelectModalShow(false);
  };

  const deleteTeamHandler = (event) => {
    event.preventDefault();
    const teamNum = Number(event.target.id.split("-")[1].replace("team", ""));
    deleteRow(teamNum);
  };

  const deleteDataHandler = async (event) => {
    event.preventDefault();
    const teamNum = Number(event.target.id.split("-")[1].replace("team", ""));

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/${englishTitle}`,
        "DELETE",
        JSON.stringify({
          userId: auth.userId,
          eventId: registState.inputs[teamNum].eventId,
          // eventTeamNumber: registState.inputs[teamNum].eventTeamNumber,
          participantIds: registState.inputs[teamNum].teamMembers
            .filter((member) => member.participantId)
            .map((member) => member.participantId),
          participantApplicationIds: registState.inputs[teamNum].teamMembers
            .filter((member) => member.participantApplicationId)
            .map((member) => member.participantApplicationId),
        }),
        {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
        `${errMsgPersonName} 삭제 실패`
      );
      // DUMMY DATA
      // const responseData = {
      //   isSuccess: true,
      //   message: "NO"
      // }

      if (responseData.isSuccess) {
        deleteRow(teamNum);
      } else {
        setError({
          title: `${errMsgPersonName} 삭제 실패`,
          detail: responseData.message,
        });
      }
    } catch (error) {}
  };

  // 단체전 페이지 들어오면 먼저 단체전 저장된 데이터 있는지 체크
  const teamListHandler = useCallback(async () => {
    try {
      let responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/${englishTitle}?userId=${auth.userId}`,
        "GET",
        null,
        {
          Authorization: `Bearer ${auth.token}`,
        },
        "단체전 선수 로드 실패"
      );

      setIsEditable(responseData.payload.isEditable);

      if (responseData.payload.isTeamExists) {
        // 겨루기 단체전이고 후보선수 없을 경우 후보선수 만들어줘야함
        responseData.payload.teams = responseData.payload.teams.map((team) => {
          // const eventName = Object.values(EVENT_ID).find(
          //   (event) => event.id === team.eventId
          // ).name;
          // if (
          //   eventName.includes("겨루기") &&
          //   team.teamMembers.filter(
          //     (member) => member.indexInTeam === "후보 선수"
          //   ).length === 0
          // ) {
          // team.teamMembers.push({
          //   indexInTeam: "후보 선수",
          //   name: "",
          //   // gender: eventName.includes("남성") ? "남성" : "여성",
          //   gender: "",
          //   isForeigner: false,
          //   nationality: "",
          //   identityNumber: "",
          //   phoneNumber: "",
          //   //weight: "",
          //   editable: false,
          // });
          // }

          // 1,2,3,후보 순으로 정렬
          team.teamMembers.sort(function (a, b) {
            if (a.indexInTeam > b.indexInTeam) return 1;
            if (a.indexInTeam < b.indexInTeam) return -1;
            return 0;
          });

          return team;
        });

        setIsRegistMode(false);
        setRegistData(
          responseData.payload.teams.map((team) => formatTeam(team, 1))
        );
        setSaveTeam(responseData.payload.teams);
      } else if (responseData.payload.isEditable && auth.period === "FIRST") {
        setIsRegistMode(true);
        setTeamSelectModalShow(true);
      }
      setApiFail(false);
    } catch (err) {
      setRegistData([]);
      setApiFail(true);
    }
  }, [auth.token, auth.period, auth.userId, sendRequest, setRegistData]);

  const teamRegistHandler = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/${englishTitle}`,
        "POST",
        JSON.stringify({
          userId: auth.userId,
          participants: registState.inputs
            .filter((team) => team.editable)
            .map((team) => formatTeam(team, 2)),
        }),
        {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
        "단체전 선수 등록 실패"
      );
      if (!responseData.isSuccess) {
        setError({
          title: `${errMsgPersonName} 등록 실패`,
          detail: responseData.message,
        });
      } else {
        setRegistData([]);
      }
    } catch (err) {
      throw err;
    }
  };

  const modifyModeHandler = (event) => {
    event.preventDefault();
    const teamNum = Number(event.target.id.split("-")[1].replace("team", ""));
    let teamsData = registState.inputs;
    let teamData = teamsData[teamNum];
    teamData.editable = true;
    teamData.teamMembers = teamData.teamMembers.map((member) => {
      if (member.indexInTeam === "후보 선수" && member.sex === "") {
        member.sex = teamData.event.includes("남성") ? "남성" : "여성";
      }

      member.foreigner = member.foreigner[0] === "Y" ? ["외국인"] : [];
      return member;
    });

    console.log(teamData);
    if (
      teamData.event.includes("겨루기") &&
      teamData.teamMembers.filter(
        (member) => member.indexInTeam === "후보 선수"
      ).length === 0
    ) {
      teamData.teamMembers.push({
        indexInTeam: "후보 선수",
        name: "",
        sex: teamData.event.includes("남성") ? "남성" : "여성",
        foreigner: [],
        nationality: "",
        idnumber: ["", "-", ""],
        phoneNumber: "",
        weight: "",
        editable: false,
      });
    }

    setRegistData(teamsData);
  };

  const modifyTeamHandler = async (event) => {
    const teamNum = Number(event.target.id.split("-")[1].replace("team", ""));
    const teamData = registState.inputs[teamNum];

    const { isValidity, message, teamMemberIndex } = checkValidity(teamNum);

    if (!isValidity) {
      setError({
        title: "입력정보 확인",
        detail: teamMemberIndex
          ? `${teamMemberIndex} : ${message}`
          : `${message}`,
      });
      return;
    }

    const formatData = formatTeam(teamData, 3, saveTeam[teamNum]);

    if (formatData) {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/${englishTitle}`,
          "PUT",
          JSON.stringify({
            userId: auth.userId,
            ...formatData,
          }),
          {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
          },

          `${errMsgPersonName} 수정 실패`
        );
        // const responseData = {
        //   isSuccess: true,
        //   message: "check please",
        // };

        if (responseData.isSuccess) {
          let teamsData = registState.inputs;
          teamsData[teamNum] = formatTeam(responseData.payload, 1);
          setRegistData(teamsData);

          let saveTeamData = saveTeam;
          saveTeamData[teamNum] = responseData.payload;
          setSaveTeam(saveTeamData);
        } else {
          setError({
            title: `${errMsgPersonName} 수정 실패`,
            detail: responseData.message,
          });
        }
      } catch (err) {
        // throw err;
      }
    } else {
      // let teamsData = registState.inputs;
      // teamsData[teamNum].editable = false;
      // setRegistData(teamsData);
      let teamsData = registState.inputs;
      teamsData[teamNum] = formatTeam(saveTeam[teamNum], 1);
      setRegistData(teamsData);
      // setRegistData(saveTeam.map((team) => formatTeam(team, 1)));
      return;
    }
  };

  const switchModeHandler = (event) => {
    event.preventDefault();

    if (isRegistMode) {
      // submit 전에 참가자 데이터 유효성 검증
      let isValidity = true;
      let errMsg;
      const teamNumber = registState.inputs.length;
      let isNewTeam = false;

      let eventCount = {
        "겨루기 남성": 0,
        "겨루기 여성": 0,
        "품새 남성": 0,
        "품새 여성": 0,
        "품새 페어": 0,
      };

      for (let i = 0; i < teamNumber; i++) {
        eventCount[registState.inputs[i].event] =
          eventCount[registState.inputs[i].event] + 1;
        if (registState.inputs[i].editable) {
          isNewTeam = true;
          const {
            isValidity: isTeamValid,
            message,
            teamMemberIndex,
          } = checkValidity(i);
          isValidity = isValidity & isTeamValid;
          if (!isTeamValid) {
            errMsg = teamMemberIndex
              ? `${i + 1}번째 팀의 ${teamMemberIndex} : ` + message
              : `${i + 1}번째 팀 : ` + message;
            break;
          }
        }
      }

      if (eventCount["겨루기 남성"] > 3 || eventCount["겨루기 여성"] > 3) {
        isValidity = false;
        errMsg =
          "겨루기 단체전 학교별로 최대 6팀(남성 3팀, 여성 3팀) 신청 가능합니다.";
      }

      if (!isNewTeam) {
        isValidity = false;
        errMsg = "단체전 한 팀 이상 신청해주세요.";
      }

      if (!isValidity) {
        setError({ title: "입력정보 확인", detail: errMsg });
        return;
      }

      // register
      teamRegistHandler()
        .then(() => {
          // list get
          teamListHandler();
        })
        .catch(() => {});
    } else {
      let isEditting = false;

      registState.inputs.forEach((team) => {
        isEditting = isEditting || team.editable;
      });
      if (isEditting) {
        setError({
          title: "",
          detail: "수정 완료 후 추가하기 버튼을 눌러주세요.",
        });
      } else {
        setIsRegistMode(true);
        setTeamSelectModalShow(true);
      }
    }
  };

  // 컴포넌트 열자마자 리스트 불러오기
  useEffect(() => {
    const checkIsSumitted = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/final-submit/is-final-submit?userId=${auth.userId}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
          },
          `최종제출여부 조회 실패`
        );

        if (responseData.isSuccess && responseData.payload.finalSubmitted) {
          setError({
            title: "최종제출 이후 수정 불가",
            detail: "최종 제출 이후에는 제출 확인만 가능합니다.",
          });
          history.push({
            pathname: "/submit",
          });
        } else if (["FIRST", "SECOND"].includes(auth.period)) {
          teamListHandler();
        }
      } catch (error) {}
    };
    checkIsSumitted();

    // // list get
    // if (["FIRST", "SECOND"].includes(auth.period)) {
    //   teamListHandler();
    // }
  }, [
    teamListHandler,
    auth.period,
    auth.token,
    auth.userId,
    history,
    sendRequest,
    setError,
  ]);

  const addTeamCloseModalHandler = (eventName) => {
    addRow(eventName);
    setTeamSelectModalShow(false);
  };

  return (
    <div className="regist-team-event regist-event" id="team-regist-event">
      <AddTeamModal
        show={teamSelectModalShow}
        onClear={closeSelectModal}
        onClick={addTeamCloseModalHandler}
      />
      <h2 className="regist-event-title">
        {isRegistMode ? "단체전 신청" : "단체전 신청확인"}
      </h2>
      {isRegistMode ? (
        <form className="regist-form">
          <div className="regist-btn-add-row">
            <Button type="button" onClick={addTeamHandler}>
              팀 추가
            </Button>
          </div>
          {registState.inputs.map((team, i) => (
            <div className="regist-team" key={`team${i}`}>
              <div className="regist-team-subtitle">
                <div>
                  <span className="regist-team-teamNumber">{i + 1}팀</span>
                  {team.event}
                </div>
                {team.editable && (
                  <Button
                    id={`btn-team${i}-delete`}
                    onClick={deleteTeamHandler}
                    type="button"
                  >
                    팀 삭제
                  </Button>
                )}
              </div>
              <RegistTeamTable
                columns={TABLE_COLUMNS_CHECK_TEAM}
                modifyColumns={
                  typeof team.event === "string" &&
                  team.event.includes("겨루기")
                    ? TABLE_COLUMNS_REGIST_TEAM_SPARRING
                    : TABLE_COLUMNS_REGIST_TEAM_FORM
                }
                data={team.teamMembers}
                inputHandler={inputHandler}
                editMode={team.editable}
                teamId={`team${i}-`}
              />
            </div>
          ))}

          <div className="regist-btn-submit">
            <Button type="button" onClick={switchModeHandler}>
              신청하기
            </Button>
          </div>
        </form>
      ) : (
        <div className="regist-form">
          {registState.inputs.map((team, i) => {
            return (
              <div className="regist-team" key={`team${i}`}>
                <div className="regist-team-subtitle">
                  <div>
                    <span className="regist-team-teamNumber">{i + 1}팀</span>
                    {team.event}
                  </div>

                  {isEditable &&
                    (team.editable ? (
                      <React.Fragment>
                        <Button
                          id={`btn-team${i}-modify`}
                          className="btn-team-modify"
                          onClick={modifyTeamHandler}
                          type="button"
                        >
                          수정완료
                        </Button>
                        <Button
                          id={`btn-team${i}-delete`}
                          className="btn-team-delete"
                          onClick={deleteDataHandler}
                          type="button"
                        >
                          삭제하기
                        </Button>
                      </React.Fragment>
                    ) : (
                      // <Button
                      //   id={`btn-team${i}-modechange`}
                      //   className="btn-team-modechange"
                      //   onClick={modifyModeHandler}
                      //   type="button"
                      // >
                      //   수정하기
                      // </Button>
                      <Button
                        id={`btn-team${i}-delete`}
                        className="btn-team-delete"
                        onClick={deleteDataHandler}
                        type="button"
                      >
                        삭제하기
                      </Button>
                    ))}
                </div>
                <RegistTeamTable
                  columns={TABLE_COLUMNS_CHECK_TEAM}
                  modifyColumns={
                    typeof team.event === "string" &&
                    team.event.includes("겨루기")
                      ? auth.period === "FIRST"
                        ? TABLE_COLUMNS_REGIST_TEAM_SPARRING
                        : TABLE_COLUMNS_REGIST_PERIOD2_TEAM_SPARRING
                      : auth.period === "FIRST"
                      ? TABLE_COLUMNS_REGIST_TEAM_FORM
                      : TABLE_COLUMNS_REGIST_PERIOD2_TEAM_FORM
                  }
                  data={team.teamMembers}
                  inputHandler={inputHandler}
                  editMode={team.editable}
                  teamId={`team${i}-`}
                />
              </div>
            );
          })}
          {auth.period === "FIRST" && isEditable && (
            <div className="check-btn-submit">
              <Button onClick={switchModeHandler} disabled={apiFail}>
                추가하기
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RegistTeam;
