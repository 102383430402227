import React from "react";

import Button from "../../../shared/components/FormElements/Button";

import "./RegistMain.css";

const RegistMain = () => {
  const PERIOD_FIRST_START = process.env.REACT_APP_PERIOD_FIRST_START;
  const PERIOD_FIRST_END = process.env.REACT_APP_PERIOD_FIRST_END;
  const PERIOD_SECOND_START = process.env.REACT_APP_PERIOD_SECOND_START;
  const PERIOD_SECOND_END = process.env.REACT_APP_PERIOD_SECOND_END;

  return (
    <div className="regist-main">
      <div className="regist-main_notice">
        {`1차 신청 기간 : ${PERIOD_FIRST_START.slice(0, 4)}.${Number(
          PERIOD_FIRST_START.slice(5, 7)
        )}.${Number(
          PERIOD_FIRST_START.slice(8, 10)
        )} - ${PERIOD_FIRST_END.slice(0, 4)}.${Number(
          PERIOD_FIRST_END.slice(5, 7)
        )}.${Number(PERIOD_FIRST_END.slice(8, 10))}`}
        <br />{" "}
        {`2차 변경 기간 : ${PERIOD_SECOND_START.slice(0, 4)}.${Number(
          PERIOD_SECOND_START.slice(5, 7)
        )}.${Number(
          PERIOD_SECOND_START.slice(8, 10)
        )} - ${PERIOD_SECOND_END.slice(0, 4)}.${Number(
          PERIOD_SECOND_END.slice(5, 7)
        )}.${Number(PERIOD_SECOND_END.slice(8, 10))}`}
        <br />
        <br />
        선수, 세컨, 자원봉사자 신청은 1차 신청 기간 내에만 가능하며
        <br />
        2차 변경 기간에는 체급 변경 및 취소만 가능합니다.
      </div>
      <div className="regist-main_btn-wrap">
        <Button to="/regist/individual" exact inverse>
          개인전 신청
        </Button>
        <Button to="/regist/team" exact inverse>
          단체전 신청
        </Button>
        <Button to="/regist/second" exact inverse>
          세컨 신청
        </Button>
        <Button to="/regist/volunteer" exact inverse>
          자원봉사자 신청
        </Button>
      </div>
    </div>
  );
};

export default RegistMain;
