import React, { useEffect, useState, useContext } from "react";

import {
  TABLE_COLUMNS_EVENT_INDIVIDUAL,
  TABLE_COLUMNS_EVENT_SECOND,
  TABLE_COLUMNS_EVENT_TEAM,
  TABLE_COLUMNS_EVENT_VOLUNTEER,
} from "../../shared/util/admin/admin-columns";
import { EVENT_ID, WEIGHT_ID } from "../../shared/util/const-event";
import { HttpContext } from "../../shared/context/http-context";
import { AuthContext } from "../../shared/context/auth-context";

import Dropdown from "../../shared/components/TableInputElements/Dropdown";

import SubmitTable from "../../user/submit/components/SubmitTable";

import "./Event.css";

const FirstEvent = () => {
  const { sendRequest } = useContext(HttpContext);
  const auth = useContext(AuthContext);

  const category1 = ["종목 선택", ...Object.keys(EVENT_ID)];
  const [category1Value, setCategory1Value] = useState(category1[0]);

  const [category2, setCategory2] = useState(["체급 선택"]);
  const [category2Value, setCategory2Value] = useState(category2[0]);

  const [tableColumns, setTableColumns] = useState(
    TABLE_COLUMNS_EVENT_INDIVIDUAL
  );
  const [tableId, setTableId] = useState("table-individual");

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let category2Num = 1;
    if (
      category1Value.includes("겨루기") &&
      category1Value.includes("개인전")
    ) {
      Object.keys(WEIGHT_ID).forEach((sex) => {
        if (category1Value.includes(sex)) {
          setCategory2(["체급 선택", ...Object.keys(WEIGHT_ID[sex])]);
          category2Num = 2;
        }
      });
    } else {
      setCategory2(["체급 선택"]);
      category2Num = 1;
    }

    if (category1Value.includes("개인전")) {
      setTableColumns(TABLE_COLUMNS_EVENT_INDIVIDUAL);
      setTableId("table-individual");
    } else if (category1Value.includes("단체전")) {
      setTableColumns(TABLE_COLUMNS_EVENT_TEAM);
      setTableId("table-team");
    } else if (category1Value.includes("세컨")) {
      setTableColumns(TABLE_COLUMNS_EVENT_SECOND);
      setTableId("table-second");
    } else if (category1Value.includes("자원봉사자")) {
      setTableColumns(TABLE_COLUMNS_EVENT_VOLUNTEER);
      setTableId("table-volunteer");
    }

    const format = (participantDatas) => {
      return participantDatas.map((participant) => ({
        ...participant,
        isForeigner: participant.isForeigner ? "Y" : "N",
      }));
    };

    const formatTeam = (teamDatas) => {
      let resultData = [];

      for (const teamData of teamDatas) {
        const teamMemberNumber = teamData.teamMembers.length;
        let resultTeamData = teamData.teamMembers.map((teamMember) => {
          return {
            indexInTeam: teamMember.indexInTeam,
            name: teamMember.name,
            gender: teamMember.gender,
            isForeigner: teamMember.isForeigner ? "Y" : "N",
            nationality: teamMember.nationality,
            identityNumber: teamMember.identityNumber,
            weight: Object.keys(WEIGHT_ID[teamMember.gender]).find(
              (key) =>
                WEIGHT_ID[teamMember.gender][key].id ===
                teamMember.weightClassId
            ),
            phoneNumber: teamMember.phoneNumber,
            eventTeamNumber: teamData.eventTeamNumber,
          };
        });

        resultTeamData[0]["rowspan"] = teamMemberNumber;
        resultTeamData[0]["universityName"] = teamData.universityName;
        resultTeamData[0]["eventTeamNumber"] = teamData.eventTeamNumber;

        resultData.push(...resultTeamData);
      }

      return resultData;
    };

    const listFirstEvent = async () => {
      try {
        if (EVENT_ID[category1Value]) {
          let url = `${process.env.REACT_APP_BACKEND_URL}/api/admin/first/event?eventId=${EVENT_ID[category1Value].id}`;

          const sexes = ["남성", "여성"];
          for (const sex of sexes) {
            if (category1Value.includes(sex)) {
              if (!WEIGHT_ID[sex][category2Value] && category2Num > 1) {
                setTableData([]);
                return;
              }

              if (
                WEIGHT_ID[sex][category2Value] &&
                category1Value.includes("겨루기") &&
                category1Value.includes("개인전")
              ) {
                url += `&weightId=${WEIGHT_ID[sex][category2Value].id}`;
              }
            }
          }

          let responseData = await sendRequest(
            url,
            "GET",
            null,
            {
              Authorization: `Bearer ${auth.token}`,
            },
            "등록내역 로드 실패"
          );
          // let responseData = {
          //   isSuccess: true,
          //   payload: [
          //     {
          //       universityName: "서울대학교",
          //       eventTeamNumber: 1,
          //       teamMembers: [
          //         {
          //           indexInTeam: "1번 선수",
          //           name: "조1",
          //           gender: "남성",
          //           isForeigner: false,
          //           identityNumber: "999999-9999999",
          //           weightClassId: 1,
          //           phoneNumber: "010-5137-8081",
          //           isFinalSubmitted: true,
          //         },
          //         {
          //           indexInTeam: "2번 선수",
          //           name: "조2",
          //           gender: "남성",
          //           isForeigner: false,
          //           identityNumber: "999999-9999999",
          //           weightClassId: 3,
          //           phoneNumber: "010-5137-8081",
          //           isFinalSubmitted: true,
          //         },
          //         {
          //           indexInTeam: "3번 선수",
          //           name: "32",
          //           gender: "남성",
          //           isForeigner: true,
          //           nationality: "캐나다",
          //           identityNumber: "999999-9999999",
          //           isFinalSubmitted: false,
          //         },
          //       ],
          //     },
          //     {
          //       universityName: "가나대학교",
          //       eventTeamNumber: 1,
          //       teamMembers: [
          //         {
          //           indexInTeam: "1번 선수",
          //           name: "조1",
          //           gender: "남성",
          //           isForeigner: false,
          //           identityNumber: "999991-9999999",
          //           weightClassId: 1,
          //           phoneNumber: "010-5137-8081",
          //           isFinalSubmitted: true,
          //         },
          //         {
          //           indexInTeam: "2번 선수",
          //           name: "조2",
          //           gender: "남성",
          //           isForeigner: false,
          //           identityNumber: "999992-9999999",
          //           weightClassId: 3,
          //           phoneNumber: "010-5137-8081",
          //           isFinalSubmitted: true,
          //         },
          //       ],
          //     },
          //   ],
          // };

          if (responseData.isSuccess && responseData.payload) {
            if (category1Value.includes("단체전")) {
              // setTableData(formatTeam(responseData.payload.registrationList));
              setTableData(formatTeam(responseData.payload));
            } else {
              // setTableData(responseData.payload.registrationList);
              setTableData(format(responseData.payload));
            }
          } else {
            setTableData([]);
          }
        }
      } catch (err) {}
    };

    listFirstEvent();
  }, [category1Value, category2Value, auth.token, sendRequest]);

  const inputEventHandler = (id, val) => {
    setCategory1Value(val);
  };
  const inputWeightHandler = (id, val) => {
    setCategory2Value(val);
  };

  return (
    <div className="event first-event">
      <h2 className="admin-title">등록 내역 - 종목별</h2>
      <div className="dropdown-items">
        <div className="dropdown-item">
          <div className="dropdown-title">종목</div>
          <Dropdown
            id="category1"
            items={category1}
            onInput={inputEventHandler}
            initialValue={category1Value}
          />
        </div>
        <div className="dropdown-item">
          <div className="dropdown-title">체급(겨루기)</div>
          <Dropdown
            id="category2"
            items={category2}
            onInput={inputWeightHandler}
            initialValue={category2Value}
            disabled={category2.length <= 1}
          />
        </div>
      </div>
      <SubmitTable
        tableId={tableId}
        columns={tableColumns}
        data={tableData}
        showNumber
      />
    </div>
  );
};

export default FirstEvent;
