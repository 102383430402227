import React, { useEffect } from "react";
import Modal from "../../../shared/components/UIElements/Modal";
// import Button from "../../../shared/components/FormElements/Button";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import "./DocuViewModal.css";
// import CheckboxBool from "../../../shared/components/TableInputElements/CheckboxBool";
import CheckboxOnChange from "../../../shared/components/TableInputElements/CheckboxOnChange";
import { deepEqual } from "../../../shared/util/deepEqual";

function areEqual(prevProps, nextProps) {
  // 같으면 true 다르면 false(ReRendering)
  // show={docuViewModalShow}
  //       onClear={closeModal}
  //       docuInfo={curDocuInfo}
  //       rightHandler={rightHandler}
  //       leftHandler={leftHandler}
  //       checkStrangeHandler={checkStrangeHandler}
  //       openHandler={openHandler}
  //       noright={
  return (
    prevProps.show === nextProps.show &&
    prevProps.noright === nextProps.noright &&
    prevProps.noleft === nextProps.noleft &&
    deepEqual(prevProps.docuInfo, nextProps.docuInfo)
  );
}

const DocuViewModal = React.memo((props) => {
  useEffect(() => {
    if (props.show && props.docuInfo.fileInfo.fileId) {
      props.openHandler(
        props.docuInfo.fileInfo.fileId,
        props.docuInfo.fileInfo.isFileStrange
      );
    }
  }, [props]);

  // keyboard 연결

  return (
    <Modal
      id="docu-view-modal"
      onCancel={props.onClear}
      headerClass="modal__header-hide"
      show={props.show}
      footerClass="modal__footer-hide"
      className="event-select-modal"
    >
      <div className="modal__title">
        <div className="participant-name">
          {props.docuInfo.fileInfo.universityName}{" "}
          {props.docuInfo.fileInfo.participantName}
        </div>
        <div className="docu-name">
          {props.docuInfo.fileInfo.fileName &&
            props.docuInfo.fileInfo.fileName.split("_")[2].split(".")[0]}{" "}
        </div>
      </div>
      <div className="tip">
        * ←:이전서류, →:다음 서류, 스페이스바: 이상 체크
      </div>

      <div className="modal__img">
        {props.docuInfo.file &&
        props.docuInfo.file.type === "application/pdf" ? (
          <iframe
            src={
              props.docuInfo.file && URL.createObjectURL(props.docuInfo.file)
            }
            width="100%"
            // height="100%"
            title="PDF Viewer"
          />
        ) : (
          <img
            src={
              props.docuInfo.file && URL.createObjectURL(props.docuInfo.file)
            }
            alt="서류 파일데이터 오류"
          />
        )}
      </div>
      <div className={`arrow-back ${props.noleft && "left-disabled"}`}>
        <ArrowBackIcon
          id="arrow-back"
          onClick={!props.noleft ? props.leftHandler : undefined}
        />
      </div>
      <div className={`arrow-forward ${props.noright && "right-disabled"}`}>
        <ArrowForwardIcon
          id="arrow-forward"
          onClick={!props.noright ? props.rightHandler : undefined}
        />
      </div>
      <div className="checkbox">
        <CheckboxOnChange
          // item={props.docuInfo.fileInfo.isFileStrange}
          item={true}
          initialValue={props.docuInfo.fileInfo.isFileStrange}
          id="checkbox"
          onChange={(id, val) => {
            // if (val !== props.docuInfo.fileInfo.isFileStrange)
            props.checkStrangeHandler(props.docuInfo.fileInfo.fileId, val);
          }}
          // showLabel
          // label="이상여부"
        />
        <div>서류 이상</div>
      </div>

      {/* <div className="modal__detail">{props.detail}</div> */}
    </Modal>
  );
}, areEqual);

export default DocuViewModal;
