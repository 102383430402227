import React from "react";
import Modal from "./Modal";

import "./PersonalInfoProtectionModal.css";

const PersonalInfoProtectionModal = (props) => {
  return (
    <Modal
      id="personal-info-protection-modal"
      onCancel={props.onClear}
      headerClass="modal__header-hide"
      show={props.show}
      footerClass="modal__footer-hide"
      className="event-select-modal"
    >
      <div className="modal__details">
        <div className="modal_detail">
          <h2>개인정보처리방침</h2>

          <p>
            <strong>전국대학태권도동아리선수권대회</strong> (이하 "대회")는
            개인정보보호법에 따라 대회 참가자의 개인정보를 보호하고 관련한
            권익을 보호하기 위하여 다음과 같은 개인정보처리방침을
            수립·공개합니다.
          </p>

          <h3>1. 개인정보 수집 및 이용 목적</h3>
          <p>
            대회는 참가자의 개인정보를 아래와 같은 목적으로 수집하고 이용합니다.
          </p>
          <ul>
            <li>
              <strong>대회 운영:</strong> 참가자 식별 및 대회 운영에 필요한
              정보를 수집합니다.
            </li>
            <li>
              <strong>보험 등록:</strong> 대회 참가자들의 안전을 보장하기 위해
              보험 가입에 필요한 고유식별정보를 수집합니다.
            </li>
          </ul>

          <h3>2. 수집하는 개인정보 항목</h3>
          <p>대회는 다음의 개인정보를 수집합니다.</p>
          <ul>
            <li>
              <strong>일반 개인정보:</strong> 성명, 성별, 생년월일, 전화번호,
              이메일주소, 체급, 계좌번호, 비밀번호 등
            </li>
            <li>
              <strong>고유식별정보:</strong> 주민등록번호, 외국인등록번호 등
              (보험 등록을 위한 용도로만 사용)
            </li>
          </ul>

          <h3>3. 개인정보의 보유 및 이용 기간</h3>
          <p>수집된 개인정보는 다음과 같은 기간 동안 보유 및 이용됩니다.</p>
          <ul>
            <li>
              <strong>일반 개인정보:</strong> 대회 종료 시까지 보유 및 이용
            </li>
            <li>
              <strong>고유식별정보:</strong> 보험 등록 완료 후, 대회 종료 시까지
              보유 및 이용
            </li>
          </ul>

          <h3>4. 개인정보의 제3자 제공</h3>
          <p>
            대회는 참가자의 개인정보를 동의 받은 범위 내에서만 이용하며,
            참가자의 사전 동의 없이는 원칙적으로 제3자에게 제공하지 않습니다.
            다만, 법령의 규정에 의하거나, 수사 목적으로 법령에 정해진 절차와
            방법에 따라 수사기관의 요구가 있을 경우 예외로 합니다.
          </p>

          <h3>5. 정보주체의 권리, 의무 및 행사 방법</h3>
          <p>
            참가자는 개인정보와 관련하여 다음과 같은 권리를 행사할 수 있습니다.
          </p>
          <ul>
            <li>
              <strong>열람 및 정정 요청:</strong> 본인의 개인정보에 대해
              열람하거나 정정을 요구할 수 있습니다.
            </li>
            <li>
              <strong>삭제 요청:</strong> 개인정보가 더 이상 필요하지 않은 경우
              삭제를 요구할 수 있습니다.
            </li>
            <li>
              <strong>동의 철회:</strong> 개인정보 수집 및 이용에 대한 동의를
              언제든지 철회할 수 있습니다.
            </li>
          </ul>

          <h3>6. 개인정보 보호를 위한 기술적·관리적 보호조치</h3>
          <p>
            대회는 참가자의 개인정보를 보호하기 위하여 다음과 같은 조치를 취하고
            있습니다.
          </p>
          <ul>
            <li>
              <strong>기술적 조치:</strong> 개인정보를 보호하기 위한 암호화
            </li>
            <li>
              <strong>관리적 조치:</strong> 개인정보 접근 권한 관리
            </li>
          </ul>

          <h3>7. 개인정보 관리책임자 및 문의처</h3>
          <p>
            개인정보와 관련된 문의, 요청 및 불만 사항은 아래의 연락처로 문의해
            주시기 바랍니다.
          </p>
          <ul>
            <li>
              <strong>개인정보 관리책임자:</strong> 조서영
            </li>
            <li>
              <strong>연락처:</strong> 010-5137-8081
            </li>
            <li>
              <strong>이메일:</strong> snu8081@naver.com
            </li>
          </ul>

          <h3>8. 개인정보처리방침 변경</h3>
          <p>
            이 개인정보처리방침은 법률이나 대회의 내부 방침에 따라 변경될 수
            있으며, 변경 시에는 웹사이트를 통해 공지됩니다.
          </p>
        </div>
        <div className="modal_detail">
          <h2>이용약관</h2>

          <h3>제1조(목적)</h3>
          <p>
            본 약관은 전국대학태권도동아리선수권대회 (이하 "대회") 신청
            웹페이지에서 제공하는 서비스(이하 "서비스"라 합니다)의 이용 조건 및
            절차, 대회 참가 신청을 위한 학교 대표자와 서비스 제공자의 권리, 의무
            및 책임 사항 등을 규정함을 목적으로 합니다.
          </p>

          <h3>제2조(정의)</h3>
          <ul>
            <li>
              1. "이용자"란 본 약관에 따라 대회 신청을 위해 서비스를 이용하는 각
              학교의 대표자를 말합니다.
            </li>
            <li>
              2. "대표자 계정"이란 대회 참가 신청을 목적으로 서비스 제공자가 각
              학교 대표자에게 부여한 계정을 말합니다.
            </li>
            <li>
              3. "선수 정보"란 각 학교 대표자가 대회 참가를 위해 입력한 학교
              선수들의 개인정보 및 관련 정보를 의미합니다.
            </li>
          </ul>

          <h3>제3조(약관의 효력 및 변경)</h3>
          <ul>
            <li>
              1. 본 약관은 서비스 화면에 게시하거나 기타의 방법으로 이용자에게
              공지함으로써 효력이 발생합니다.
            </li>
            <li>
              2. 서비스는 필요 시 관련 법령을 위배하지 않는 범위 내에서 본
              약관을 변경할 수 있으며, 변경된 내용을 서비스 화면에 공지합니다.
            </li>
            <li>
              3. 이용자가 변경된 약관에 동의하지 않을 경우 서비스 이용을 중단할
              수 있습니다. 변경된 약관의 효력 발생 이후에도 서비스를 계속 사용할
              경우 변경된 약관에 동의한 것으로 간주됩니다.
            </li>
          </ul>

          <h3>제4조(서비스의 제공 및 변경)</h3>
          <ul>
            <li>
              1. 서비스는 각 학교 대표자가 대회에 참가할 선수 정보를 입력하고
              관리할 수 있는 기능을 제공합니다.
            </li>
            <li>
              2. 서비스는 대회 운영 및 관련 사정에 따라 서비스 내용을 변경할 수
              있으며, 변경된 사항은 이용자에게 공지됩니다.
            </li>
          </ul>

          <h3>제5조(계정 관리)</h3>
          <ul>
            <li>
              1. 대표자 계정은 서비스 제공자가 각 학교 대표자에게 직접 부여하며,
              대표자는 부여된 계정을 사용하여 자교의 선수 정보를 입력하고 대회
              신청을 진행합니다.
            </li>
            <li>
              2. 대표자는 본인의 계정 정보를 제3자에게 양도하거나 대여할 수
              없으며, 계정 정보가 부정하게 사용된 경우 즉시 서비스 제공자에게
              통보하고 필요한 조치를 취해야 합니다.
            </li>
            <li>
              3. 대표자는 선수 정보 입력 시 정확한 정보를 기입해야 하며, 잘못된
              정보로 인해 발생하는 문제에 대한 책임은 대표자에게 있습니다.
            </li>
            <li>
              4. 최종 제출 전에는 신청 확인 과정을 통해 입력된 등록 정보가
              올바른지 반드시 확인해야 하며, 확인하지 않고 제출한 경우 주최 측은
              그에 대한 책임을 지지 않습니다.
            </li>
            <li>
              5. 최종 제출 후에는 입력된 정보의 수정이 불가능하며, 신중한 확인
              후 제출하여야 합니다.
            </li>
          </ul>

          <h3>제6조(이용자의 의무)</h3>
          <ul>
            <li>1. 대표자는 다음 행위를 하여서는 안 됩니다.</li>
            <ul>
              <li>가. 타인의 개인정보를 도용하는 행위</li>
              <li>
                나. 서비스의 운영을 방해하거나 불법적인 정보를 유포하는 행위
              </li>
              <li>다. 대회의 운영 목적에 반하는 행위</li>
              <li>라. 법령에 위반되는 행위</li>
            </ul>
            <li>
              2. 대표자는 본 약관 및 서비스가 정한 모든 규정을 준수하여야 하며,
              전국태권도동아리연합회 연합회칙을 준수하여야 합니다.
            </li>
          </ul>

          <h3>제7조(개인정보보호)</h3>
          <ul>
            <li>
              1. 서비스는 대표자 및 선수들의 개인정보를 보호하며, 개인정보
              처리에 관한 사항은 별도의 <strong>개인정보처리방침</strong>에
              따릅니다.
            </li>
            <li>
              2. 서비스는 법령에 따라 개인정보를 안전하게 보호하며, 동의 없이는
              제3자에게 제공하지 않습니다.
            </li>
          </ul>

          <h3>제8조(서비스의 중단)</h3>
          <ul>
            <li>
              1. 서비스는 컴퓨터 등 정보통신설비의 유지·보수, 교체 및 고장,
              서비스 변경 등의 사유로 서비스 제공을 일시적으로 중단할 수
              있습니다.
            </li>
            <li>
              2. 천재지변, 전쟁, 비상사태 등 불가항력적인 사유가 발생한 경우
              서비스 제공을 중단할 수 있습니다.
            </li>
          </ul>

          <h3>제9조(책임의 한계)</h3>
          <ul>
            <li>
              1. 서비스는 무료로 제공되며, 이용자에게 발생하는 어떠한 손해에
              대해서도 책임을 지지 않습니다.
            </li>
            <li>
              2. 서비스는 이용자의 귀책 사유로 인한 서비스 이용 장애에 대해
              책임을 지지 않습니다.
            </li>
            <li>
              3. 서비스에서 제공되는 정보는 대회의 원활한 운영을 위한 참고자료일
              뿐, 그 정확성 및 신뢰성에 대해 보증하지 않습니다.
            </li>
          </ul>

          <h3>제10조(저작권의 귀속 및 이용제한)</h3>
          <ul>
            <li>
              1. 서비스에서 제공되는 모든 콘텐츠에 대한 저작권은 서비스
              개발자(조서영, 한선희)에게 귀속됩니다.
            </li>
            <li>
              2. 이용자는 서비스를 통해 얻은 정보를 주최 측의 사전 승낙 없이
              복제, 전송, 배포, 상업적 이용을 할 수 없습니다.
            </li>
          </ul>

          <h3>제11조(분쟁 해결)</h3>
          <ul>
            <li>
              1. 서비스 이용과 관련하여 발생한 분쟁에 대해 서비스 제공자와
              이용자는 상호 협의하여 해결합니다.
            </li>
            <li>
              2. 협의가 이루어지지 않을 경우, 관련 법령에 따른 소송 절차에
              따릅니다.
            </li>
          </ul>

          <h3>제12조(준거법 및 관할법원)</h3>
          <p>
            본 약관과 관련된 사항은 대한민국 법률에 따르며, 분쟁 발생 시 관할
            법원은 서비스 제공자의 소재지를 관할하는 법원으로 합니다.
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default PersonalInfoProtectionModal;
