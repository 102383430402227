import React from "react";

import inputField from "../../regist/components/TableInputField";

import { deepEqual } from "../../../shared/util/deepEqual";

function areEqual(prevProps, nextProps) {
  // 같으면 true 다르면 false
  return (
    deepEqual(prevProps.rowData, nextProps.rowData) &&
    deepEqual(prevProps.columns, nextProps.columns)
  );
}

const SubmitTableRow = React.memo(
  ({
    rowData,
    rowIdx,
    showNumber,
    cssGrayBackground,

    columns,

    inputHandler,
    buttonHandler,
  }) => {
    return (
      <tr
        className={`${rowData.universityName === "합계" && "row-last-sum"} ${
          !rowData.finalSubmitted && cssGrayBackground && "row-gray-background"
        } ${rowData.styleRedColor && "row-red-color"} `}
      >
        {showNumber && (
          <td>{rowData.universityName === "합계" ? "" : rowIdx + 1}</td>
        )}
        {columns.map((col, j) => {
          if (
            (col.id === "depositConfirmed" || col.id === "refundConfirmed") &&
            rowData[col.id]
          ) {
            return (
              <td
                id={"row" + rowIdx + "col" + j}
                key={"row" + rowIdx + "col" + j}
                className={`${col.id} btn-reverse`}
              >
                {inputField(
                  {
                    ...col,
                    detail: {
                      content:
                        col.id === "depositConfirmed" ? "입금완료" : "환불완료",
                    },
                  },
                  rowData[col.id],
                  rowIdx,
                  j,
                  j,
                  inputHandler,
                  buttonHandler,
                  null,
                  null
                )}
              </td>
            );
          } else if (
            (col.id === "depositConfirmed" || col.id === "refundConfirmed") &&
            (rowData.universityName === "합계" || !rowData.finalSubmitted) &&
            // rowData[col.id] === undefined
            !rowData[col.id]
          ) {
            return (
              <td
                key={"row" + rowIdx + "col" + j}
                className={`${col.id} btn-reverse`}
              ></td>
            );
          } else if (
            // 단체전 열합치기
            Object.keys(rowData).includes("rowspan") &&
            ["universityName", "eventTeamNumber"].includes(col.id)
          ) {
            return (
              <td
                rowSpan={rowData["rowspan"]}
                key={"row" + rowIdx + "col" + j}
                className={col.id}
              >
                {inputField(
                  col,
                  rowData[col.id],
                  rowIdx,
                  j,
                  j,
                  inputHandler,
                  buttonHandler,
                  null,
                  null
                )}
              </td>
            );
          } else if (
            // 단체전
            Object.keys(rowData).includes("eventTeamNumber") &&
            ["universityName", "eventTeamNumber"].includes(col.id)
          ) {
            return <React.Fragment key={"row" + rowIdx + "col" + j} />;
          }

          return (
            <td key={"row" + rowIdx + "col" + j} className={col.id}>
              {inputField(
                col,
                rowData[col.id],
                rowIdx,
                j,
                j,
                inputHandler,
                buttonHandler,
                null,
                null
              )}
            </td>
          );
        })}
      </tr>
    );
  },
  areEqual
);

export default SubmitTableRow;
