import React, { useEffect, useState, useContext } from "react";

import { TABLE_COLUMNS_FIRST_UNIVERSITY } from "../../shared/util/admin/admin-columns";
import { HttpContext } from "../../shared/context/http-context";
import { AuthContext } from "../../shared/context/auth-context";

import SubmitTable from "../../user/submit/components/SubmitTable";

import "./University.css";

const FirstUniversity = () => {
  const { sendRequest } = useContext(HttpContext);
  const auth = useContext(AuthContext);

  const [tableData, setTableData] = useState([
    // {
    //   universityName: "서울대학교",
    //   userName: "김재윤",
    //   isFinalSubmitted: true,
    //   phoneNumber: "010-1111-1111",
    //   participantCount: 10,
    //   secondCount: 5,
    //   volunteerCount: 8,
    //   totalParticipantCount: 20,
    //   depositAmount: 30000,
    //   depositorName: "금자씨",
    //   isDepositConfirmed: false,
    // },
    // {
    //   universityName: "서울대학교2",
    //   userName: "김재윤",
    //   isFinalSubmitted: false,
    //   phoneNumber: "010-1111-1111",
    //   participantCount: 10,
    //   secondCount: 5,
    //   volunteerCount: 8,
    //   totalParticipantCount: 20,
    //   depositAmount: 30000,
    //   depositorName: "금자씨",
    //   isDepositConfirmed: false,
    // },
  ]);

  const checkDepositHandler = async (event) => {
    event.preventDefault();
    event.persist();

    const idArray = event.target.id.split("-");
    const rowNum = Number(idArray[0].replace("nullrow", ""));
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/first/university?userId=${tableData[rowNum].userId}`,
        "POST",
        JSON.stringify({
          ...tableData[rowNum],
          depositConfirmed: !tableData[rowNum]["depositConfirmed"],
        }),
        {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
        "입금확인 실패"
      );

      if (responseData.isSuccess) {
        setTableData((prevVal) => {
          let changeRow = { ...prevVal[rowNum] };
          changeRow["depositConfirmed"] = !changeRow.depositConfirmed;
          prevVal[rowNum] = changeRow;
          return [...prevVal];
        });
      }
    } catch (err) {}
  };

  useEffect(() => {
    const listFirstUniversity = async () => {
      try {
        let responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/admin/first/university`,
          "GET",
          null,
          {
            Authorization: `Bearer ${auth.token}`,
          },
          "등록내역 로드 실패"
        );

        if (responseData.isSuccess && responseData.payload) {
          setTableData(
            // responseData.payload.registrationList.map((reg) => {
            responseData.payload.map((reg) => {
              if (!reg.isFinalSubmitted) {
                delete reg.isDepositConfirmed;
              }
              return reg;
            })
          );
        }
      } catch (err) {}
    };

    listFirstUniversity();
  }, [auth.token, sendRequest]);

  return (
    <div className="university first-university">
      <h2 className="admin-title">등록 내역 - 학교별</h2>
      <div className="description">
        * 음영처리된 학교는 아직 최종제출 하지 않은 학교입니다
      </div>
      <SubmitTable
        columns={TABLE_COLUMNS_FIRST_UNIVERSITY}
        data={tableData}
        showNumber
        buttonHandler={checkDepositHandler}
        cssGrayBackground
      />
    </div>
  );
};

export default FirstUniversity;
